import { ethers } from 'ethers';
import { getProvider } from '../wallet/ether';
import { MarketPlace } from '../epicHero/EpicHeroCst';
import { typeHero } from '../epicHero/EpicHero';

async function executeTrade(tradeId, type) {
  try {
    const provider = await getProvider();
    const signer = provider.getSigner();
    const marketAddr = (type === typeHero.genesis) ? MarketPlace.AddrGenesis : MarketPlace.AddrDemi;
    const tokenContractGenesis = new ethers.Contract(
      marketAddr,
      MarketPlace.Abi,
      signer,
    );
    await tokenContractGenesis.executeTrade(tradeId);
  } catch (error) {
    console.log(error);
    if (error.code !== 4001) throw new Error(error);
  }
}

export default executeTrade;
