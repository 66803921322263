import { ethers } from 'ethers';
import postDataAxios from '../../api/getepichero';
import { EpicHeroCst } from './EpicHeroCst';

export const typeHero = {
  demi: 'Demi',
  genesis: 'Genesis',
};
Object.freeze(typeHero);
const EnumRarity = ['chest', 'Common', 'Uncommon', 'Rare', 'Super Rare', 'Legendary', 'Mythical', 'Epic'];
const EnumElement = ['unknown', 'Fire', 'Light', 'Metal', 'Thunder', 'Wood', 'Wind', 'Earth', 'Darkness', 'Water', 'Ice'];
const EnumClassName = ['unknown', 'Warrior', 'Thief', 'Mage'];
const baseValueGenesisThoreum = [0, 150, 250, 350, 550, 1400, 5000, 9500];
const baseValueDemiThoreum = [0, 3.5, 10, 38, 125, 400, 1400, 4444];

const priceEvolutionGenesisEpic = [0, 200, 500, 1500, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000];
const priceEvolutionGenesisThoreum = [0, 0, 0, 0, 40.00, 50.00, 60.00, 70.00, 80.00, 90.00, 100.00, 110.00, 120.00];
const failureProbabilityGenesis = [0, 0, 0, 0, 0, 24, 28, 32, 36, 40, 44, 48, 52];

const priceEvolutionDemiEpic = [0, 10, 25, 75, 225, 250, 300, 350, 400, 450, 500, 550, 600];
const priceEvolutionDemiThoreum = [0, 0, 0, 0, 2.25, 2.5, 3, 3.50, 4.00, 4.50, 5.00, 5.50, 6.00];
const failureProbabilityDemi = [0, 0, 0, 0, 0, 24, 28, 32, 36, 40, 44, 48, 52];

const mlvl = [0, 1, 1.6, 3, 7.5, 22, 40, 60, 80, 100, 120, 140, 160, 180];
const mrarity = [0, 1, 1.5, 2, 2.5, 3, 3.5, 4];

const medianStat = [0, 162.8, 215.6, 269.5, 321.4, 373.2, 427.4, 478.5];
const standardDev = 5; // ecart-type

class EpicHero {
  constructor(type, num, level = 0, rarity = 0, task = 'free') {
    this.ID = num;
    this.level = level;
    this.rarity = rarity;
    this.name = '';
    this.className = '';
    this.atk = 0;
    this.type = type;
    this.task = task;
    this.value = 0.0;
    this.stat = {};
    this.getInfo = false;
  }

  fillValues({
    level, rarity, name, className, stat, priceTH, priceEH,
  }) {
    this.level = level;
    this.rarity = rarity;
    this.name = name;
    this.className = className;
    this.stat = stat;
    this.getInfo = true;
    this.computeValue({ epicheroPrice: priceEH, thoreumPrice: priceTH });
  }

  goodDeal(value) {
    let good = '';
    let sumStat = 0;
    let msg = '';
    let rarity = 0;
    if (typeof this.rarity === 'string') rarity = EpicHero.getValueRarity(this.rarity);
    else rarity = this.rarity;
    const basevalue = (this.type === typeHero.genesis) ? baseValueGenesisThoreum[rarity] : baseValueDemiThoreum[rarity];
    if (this.getInfo) sumStat = Object.values(this.stat).reduce((total, amount) => total + amount);
    if (sumStat <= (medianStat[rarity] - standardDev)) {
      good = 'bad';
      msg = 'Statistics are too low (inferior to median minus one standard deviation)';
    }
    if (value > (basevalue * 1.3)) {
      good = 'bad';
      msg = 'Price is too high (more than 30% of standard price)';
    }
    if (sumStat >= medianStat[rarity] && value < (basevalue * 1.2)) {
      good = 'good';
      msg = 'Statitics is higher than median and price is below 120% of standard price';
    }
    return { good, msg };
  }

  static getName(character) {
    let name = '';
    switch (character) {
      case 1:
        name = 'Zeus';
        break;
      case 2:
        name = 'Loki';
        break;
      case 3:
        name = 'Medusa';
        break;
      case 4:
        name = 'Poseidon';
        break;
      case 5:
        name = 'Monkey King';
        break;
      case 6:
        name = 'Athena';
        break;
      case 7:
        name = 'Cleopatra';
        break;
      case 8:
        name = 'Ne Zha';
        break;
      case 9:
        name = 'Dragon King of Lac';
        break;
      case 10:
        name = 'Garuda';
        break;
      case 11:
        name = 'Aphrodite';
        break;
      case 12:
        name = 'Anubis';
        break;
      case 13:
        name = 'Au Co';
        break;
      case 14:
        name = 'Curupira';
        break;
      case 15:
        name = 'Genesha';
        break;
      case 16:
        name = 'LaPu Lapu';
        break;
      case 17:
        name = 'Mulan';
        break;
      case 18:
        name = 'Ra';
        break;
      case 19:
        name = 'Kratos';
        break;
      case 20:
        name = 'Thor';
        break;
      default:
        break;
    }
    return name;
  }

  static getClassName(numClassName) {
    if (numClassName > 0 && numClassName < EnumClassName.length) { return EnumClassName[numClassName]; }
    return EnumClassName[0];
  }

  static getElement(elem) {
    if (elem > 0 && elem < EnumElement.length) { return EnumElement[elem]; }
    return EnumElement[0];
  }

  getAtk() {
    let rarity = 0;
    if (typeof this.rarity === 'string') {
      rarity = EpicHero.getValueRarity(this.rarity);
    } else {
      rarity = this.rarity;
    }
    const base = (this.type === typeHero.genesis) ? 10000 : 500;
    return base * mlvl[this.level] * mrarity[rarity];
  }

  showRarity() {
    if (typeof this.rarity === 'string') {
      return this.rarity;
    }
    return EnumRarity[this.rarity];
  }

  static getRarity(rare) {
    if (typeof rare === 'string') {
      return rare;
    }
    return EnumRarity[rare];
  }

  static getValueRarity(rarity) {
    return EnumRarity.findIndex((rare) => rarity === rare);
  }

  computeValue({ epicheroPrice, thoreumPrice }) {
    let value = 0.0;
    let rarity = 0;
    if (typeof this.rarity === 'string') {
      rarity = EpicHero.getValueRarity(this.rarity);
    } else {
      rarity = this.rarity;
    }
    value = (this.type === typeHero.genesis) ? baseValueGenesisThoreum[rarity] : baseValueDemiThoreum[rarity];
    value *= thoreumPrice; // base price in dollar
    let updateCost = 0.0;
    for (let index = 0; index < this.level; index += 1) {
      if (this.type === typeHero.genesis) {
        // eslint-disable-next-line max-len
        updateCost += ((priceEvolutionGenesisEpic[index] * epicheroPrice) + (priceEvolutionGenesisThoreum[index] * thoreumPrice)) / ((100 - failureProbabilityGenesis[index]) / 100.0);
      } else {
        // eslint-disable-next-line max-len
        updateCost += ((priceEvolutionDemiEpic[index] * epicheroPrice) + (priceEvolutionDemiThoreum[index] * thoreumPrice)) / ((100 - failureProbabilityDemi[index]) / 100.0);
      }
    }
    this.value = value + updateCost;
  }

  static async getInfoCharacters(heroes) {
    const tabHero = { heroIds: [], demiIds: [] };
    const newHero = [];
    if (!heroes) return newHero;
    for (let index = 0; index < heroes.length; index += 1) {
      const hero = heroes[index];
      if (hero.type === typeHero.demi) {
        tabHero.demiIds.push(hero.ID);
      } else if (hero.type === typeHero.genesis) {
        tabHero.heroIds.push(hero.ID);
      }
    }
    if (tabHero.heroIds.length > 0 || tabHero.demiIds.length) {
      let resp;
      try {
        resp = await postDataAxios(tabHero);

        heroes.forEach((element) => {
          let found = null;
          if (element.type === typeHero.genesis) {
            found = resp.data.hero.find((hero) => element.ID === hero.heroId);
          } else if (element.type === typeHero.demi) {
            found = resp.data.demi.find((hero) => element.ID === hero.heroId);
          }
          if (found) {
            const copy = element;
            copy.name = EpicHero.getName(found.character);
            copy.className = EpicHero.getClassName(found.className);
            copy.rarity = element.showRarity();
            copy.atk = element.getAtk();
            copy.stat = {
              agi: found.agi, end: found.end, int: found.int, luk: found.luk, mag: found.mag, str: found.str,
            };
            copy.mana = found.mana;
            // agi, end, int,luk, mag, str
            copy.getInfo = true;
            newHero.push(copy);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    return newHero;
  }

  static async getHeroInfo(HeroIds, provider, type) {
    let nft = null;
    const dataHero = [];
    if (type === typeHero.genesis) nft = EpicHeroCst.EpicHeroNFTGenesisAddr;
    else if (type === typeHero.demi) nft = EpicHeroCst.EpicHeroNFTDemiAddr;
    let res = null;
    if (HeroIds.length > 0) {
      const EpicHeroNFT = new ethers.Contract(nft, EpicHeroCst.EpicHeroNFTAbi, provider);
      res = [];
      HeroIds.forEach((hero) => {
        res.push(EpicHeroNFT.getHero(hero.ID));
      });
      res = await Promise.allSettled(res);

      for (let index = 0; index < res.length; index += 1) {
        const e = res[index];
        if (e.status === 'fulfilled') {
          const item = e.value;
          if (item !== 'undefined') {
            dataHero.push(new EpicHero(type, HeroIds[index].ID, item.level, item.rarity, HeroIds[index].Daily));
          }
        }
      }
    }
    return dataHero;
  }
}

export default EpicHero;
