/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react';

const { anychart } = window;

/**
 * AnyChart React plugin.
 */
class AnyChart extends React.Component {
  constructor(props) {
    super(props);
    /**
     * Instance (stage or chart).
     * @type {Object}
     */
    this.instance = null;

    /**
     * Whether instance is stage.
     * @type {boolean}
     */
    this.isStage = false;

    /**
     * Should we dispose instance or not.
     * @type {boolean}
     */
    this.disposeInstance = false;

    /**
     * Properties of AnyChart which expected as array of [entity_index, json].
     * E.g. <AnyChart yAxis={[1, {orientation: 'right'}]} />
     * @type {Array.<string>}
     */
    this.multipleEntities = ['xAxis', 'yAxis', 'lineMarker', 'rangeMarker', 'textMarker', 'grid', 'minorGrid'];

    /**
     * Container for chart/stage.
     * @type {string}
     */
    this.containerId = props.id || 'ac-chart-container';
  }

  /**
   * Component has rendered.
   */
  componentDidMount() {
    this.createAndDraw({});
  }

  shouldComponentUpdate() {
    return false;
  }
  // UNSAFE_componentWillUpdate(nextProps, nextState) {
  //   this.containerId = nextProps.id || this.containerId;
  // }

  /**
   * Component has re-rendered.
   * @param {Object} prevProps Previous properties.
   * @param {Object} prevState Previous state.
   */
  componentDidUpdate(prevProps) {
    const props = { ...prevProps };
    delete props.type;
    delete props.instance;
    this.createAndDraw(props);
  }

  /**
   * Unmount react component.
   */
  componentWillUnmount() {
    this.removeInstance();
  }

  /**
   * Create instance to render chart or use instance property.
   * @param {Object} props Properties.
   */
  createInstance(props) {
    if (props.instance) {
      this.removeInstance();
      this.instance = props.instance;
      this.isStage = ((typeof this.instance.draw) !== 'function');
      delete props.instance;
      this.disposeInstance = false;
    } else if (props.type) {
      this.removeInstance();
      this.disposeInstance = true;
      this.instance = anychart[props.type](props.data);
      this.isStage = false;
      delete props.type;
      delete props.data;
    }
    if (this.instance) { this.instance.container(this.containerId); }
    delete props.id;
  }

  /**
   * Draws chart.
   * @param {Object} props Properties.
   */
  drawInstance(props) {
    if (!this.instance) return;
    if (this.isStage) {
      this.instance.suspend();
      const { charts } = props;
      delete props.charts;
      this.applyProps(props);
      for (const chart of charts) {
        chart.container(this.instance).draw();
      }
      this.instance.resume();
    } else {
      this.applyProps(props);
      this.instance.draw();
    }
  }

  /**
   * Method that
   * @param {Object} prevProps
   */
  createAndDraw(prevProps) {
    const props = Object.assign(prevProps, this.props);
    this.createInstance(props);
    this.drawInstance(props);
  }

  /**
   * Remove instance (dispose it if necessary).
   */
  removeInstance() {
    if (this.instance) {
      if (this.disposeInstance) { this.instance.dispose(); } else if (this.isStage) this.instance.remove();
      else this.instance.container().getStage().remove();
    }
  }

  /**
   * Checker for array.
   * @param {*} value Value to check.
   * @return {boolean}
   */
  isArray(value) {
    return ((typeof value === 'object') && (value instanceof Array));
  }

  /**
     * Applies props.
     * @param {Object} props Properties.
     */
  applyProps(props) {
    for (const key of Object.keys(props)) {
      let value = props[key];
      if ((key === 'width' || key === 'height') && !this.isStage) { this.instance.container().getStage()[key](value); }

      if (this.instance[key]) {
        if (~this.multipleEntities.indexOf(key)) {
          if (!this.isArray(value)) { value = [value]; }
          this.instance[key](...value);
        } else { this.instance[key](value); }
      }
    }
  }

  /**
   * Render container for future chart drawing.
   */
  render() {
    return (
      <div id={this.containerId} />
    );
  }
}
/**
 * Default export.
 */
export default AnyChart;
