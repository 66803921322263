import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPrices, setPolling } from '../store/priceSlice';

const getCoingeckoSimplePriceUri = (baseId, quoteId) => `https://api.coingecko.com/api/v3/simple/price?ids=${baseId}&vs_currencies=${quoteId}`;

const fetchCoingeckoPrice = (fetchFunction) => async (base, quote) => {
  try {
    const baseId = base.toLowerCase();
    const quoteId = quote.toLowerCase();
    const url = getCoingeckoSimplePriceUri(baseId, quoteId);
    const data = await fetchFunction(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await data.json();
    const price = {};
    // eslint-disable-next-line no-return-assign
    Object.entries(result).forEach((val) => price[val[0]] = val[1][quoteId]);
    return price;
  } catch (_) {
    return undefined;
  }
};

const getCoingeckoPrice = fetchCoingeckoPrice(typeof window !== 'undefined' && window.fetch);
export default getCoingeckoPrice;

export const useGetPrices = () => {
  const { polling, price, interval } = useSelector((state) => state.coinGeckoPrices);
  // const interval = useSelector((state) => state.coinGeckoPrices.interval);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getPrice() {
      // let prices = defautPrice;
      if (polling) {
        const newPrice = { ...price };
        const tokenPrice = await getCoingeckoPrice('epichero,multiverse-capital,thoreum-v2,libero-financial', 'usd');
        newPrice.epichero = tokenPrice.epichero;
        newPrice.mvc = tokenPrice['multiverse-capital'];
        newPrice.thoreum = tokenPrice['thoreum-v2'];
        newPrice.libero = tokenPrice['libero-financial'];
        console.debug(`update prices: ${JSON.stringify(newPrice, null, 1)}`);
        dispatch(setPrices(newPrice));
      }
    }

    getPrice();
    const timeout = setInterval(() => {
      getPrice();
    }, interval);
    return () => { clearInterval(timeout); dispatch(setPolling(false)); };
  }, [dispatch, interval, polling, price]);

  useEffect(() => {
    dispatch(setPolling(true));
  }, [dispatch]);

  return price;
};
