import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ErrorBoundary from './routes/ErrorBoundary';

import Sidebar from './routes/Sidebar';
import Footer from './routes/footer';
import ErrorPage from './routes/ErrorPage';
import ComingSoon from './routes/comingsoon';

import Home from './components/home';
import Stat from './components/Stats';
import HeroStats from './components/HeroStats/HeroStats';
import Army from './components/army/Army';
import Dungeon from './components/dungeon/dungeon';
import Holders from './components/holders/Holders';
import Market from './components/market/Market';
import XLibero from './components/xLibero';
import XLibera from './components/xLibera';
import ErrorHandler from './components/ErrorHandler';

const App = function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <div className="wrapper">
          <Sidebar />
          <div id="content">
            <ErrorHandler>
              <div className="container-fluid">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/holders" element={<Holders />} />
                  <Route path="/army" element={<Army />}>
                    <Route path=":address" element={<Army />} />
                  </Route>
                  <Route path="/market" element={<Market />} />
                  <Route path="/roi" element={<ComingSoon />} />
                  <Route path="/xlibero" element={<XLibero />} />
                  <Route path="/xlibera" element={<ComingSoon />} />
                  <Route path="/EHstats" element={<Stat />} />
                  <Route path="/HeroStats" element={<HeroStats />} />
                  <Route path="/dungeon" element={<Dungeon />} />
                  <Route path="/fuse" element={<ComingSoon />} />
                  <Route path="/logs" element={<ComingSoon />} />

                  {/* OTHER PAGES */}
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </div>
            </ErrorHandler>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
