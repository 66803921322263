import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';

import CustomLoader from '../CustomLoader';
import { getMarketActive, useGetMarketData } from './getMarket';
import MarketTable from './marketTable';
import { typeHero } from '../epicHero/EpicHero';

function ShowMarketTable({ type }) {
  const { result, isLoading } = useGetMarketData(type);
  const { heroes, authorizeToBuy } = result;

  if (isLoading) {
    return (
      <div>
        <CustomLoader />
      </div>
    );
  }

  return (
    <div>
      <p className="text-end mb-0">only heroes, not chests</p>
      <p className="text-end m-2 mt-0">{heroes.length} {type} heroes in market</p>
      <MarketTable data={heroes} authorized={authorizeToBuy} />
    </div>
  );
}

function ShowMarket() {
  const [stateType, setType] = useState(null);
  const [isMarketActive, setIsMarketActive] = useState(false);
  const componentMounted = useRef(true);
  // const { getProvider } = useWeb3();

  useEffect(() => {
    getMarketActive()
      .then((data) => {
        if (componentMounted.current) {
          setIsMarketActive(data);
        }
      });
    return () => {
      // executed when unmount
      componentMounted.current = false;
    };
  }, []);

  let marketActive = '';
  if (isMarketActive !== null) {
    marketActive = (
      <div className="fs-5 text-end p-0 m-0">
        <p className="p-0 m-0">Genesis marketPlace is: {(isMarketActive.genesis) ? <span className="text-success fs-4">ACTIVE</span> : <span className="text-danger fs-4">INACTIVE</span>}</p>
        <p className="p-0 m-0">Demi marketPlace is: {(isMarketActive.demi) ? <span className="text-success fs-4">ACTIVE</span> : <span className="text-danger fs-4">INACTIVE</span>}</p>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="card has-shadow px-0 rounded">
          <div className="card-header">
            <p className="fs-2 m-0">MarketPlace:</p>
            {marketActive}
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-around">
              <button type="button" className={`btn btn-lg btn-primary has-shadow w-25 ${(stateType === typeHero.genesis) ? 'disabled' : ''} ${(isMarketActive.genesis) ? '' : 'disabled'}`} onClick={() => setType(typeHero.genesis)}>Genesis</button>
              <button type="button" className={`btn btn-lg btn-primary has-shadow w-25 ${(stateType === typeHero.demi) ? 'disabled' : ''} ${(isMarketActive.demi) ? '' : 'disabled'}`} onClick={() => setType(typeHero.demi)}>Demi</button>
            </div>
            {stateType && <ShowMarketTable type={stateType} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowMarket;

ShowMarketTable.propTypes = {
  type: PropTypes.string.isRequired,
};
