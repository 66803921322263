import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetHoldersQuery } from '../../store/myApiSlice';

import ChartHolders from './chartHolders';
import { setError } from '../../store/errorSlice';

import CustomLoader from '../CustomLoader';

export const legend = [
  [0x1F41A, 'Seashell', ' > 2$'],
  [0x1F40C, 'Snail', ' > $10'],
  [0x1F990, 'Shrimp', ' > $100'],
  [0x1F41F, 'Fish', ' > $500'],
  [0x1F419, 'Octopus', ' > $2000'],
  [0x1F991, 'Squid', ' > $5\'000'],
  [0x1F42C, 'Dolphin', ' > $10\'000'],
  [0x1F988, 'Shark', ' > $25\'000'],
  [0x1F40B, 'Whale', ' > $50\'000'],
  [0x1F433, 'Humpback', ' > $100\'000'],
];

function showLegend() {
  return (
    // <div className="card" style={{ width: '20rem' }}>
    <div className="card has-shadow">
      <div className="card-header pt-4 fs-4">Which animal are you ?</div>
      <div className="card-body">
        {legend.map((item) => (
          <div className="d-flex flex-stack mb-8" key={item[1]}>
            <div className="d-flex align-items-center me-5">
              <span className="" role="img" aria-label="">
                {String.fromCodePoint(item[0])}
              </span>
              {item[1]}
            </div>
            <div>
              {item[2]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
function someInfo(epoch) {
  const myDate = new Date(epoch);
  return (
    <div className="card has-shadow">
      <div className="card-header pt-4 fs-4">Some information</div>
      <div className="card-body">
        <ul>
          <li key="a">Types of holders are calculate every 24h</li>
          <li key="b">we remove all addresses with less than 2$, this amount is less than the first airdrop of thoreum</li>
          <li key="c">{'We create a custom script to get all transactions \'to\' and \'from\' token addresses to make a database with all addresses'}</li>
          <li key="d">{`the last update was at ${myDate.toLocaleString()}`}</li>
        </ul>
      </div>
    </div>
  );
}

const Holders = function Holders() {
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetHoldersQuery();

  let content = null;
  let dataHolders;
  if (isLoading) {
    content = <CustomLoader />;
  } else if (isError) {
    dispatch(setError(error.message || error.status));
  } else if (isSuccess && data.status === 'success') {
    dataHolders = data.data;
    const min = [dataHolders.MVC.time, dataHolders.thoreum.time, dataHolders.epicHero.time, dataHolders.libero.time];
    const lastUpdate = Math.min(...min);

    content = (
      <div className="flex-container">
        <div className="row">
          <div className="col-md-6 mb-4 col-xl-4">
            {showLegend()}
          </div>
          <div className="col-md-6 mb-4 col-xl-8">
            {someInfo(lastUpdate)}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header fs-4">Thoreum holders</div>
              <div className="card-body">
                {dataHolders.thoreum && <ChartHolders data={dataHolders.thoreum} containerName="chartTH" />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header fs-4">EpicHero holders</div>
              <div className="card-body">
                {dataHolders.epicHero && <ChartHolders data={dataHolders.epicHero} containerName="chartEH" />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header fs-4">MVC holders</div>
              <div className="card-body">
                {dataHolders.MVC && <ChartHolders data={dataHolders.MVC} containerName="chartMVC" />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header fs-4">Libero holders</div>
              <div className="card-body">
                {dataHolders.libero && <ChartHolders data={dataHolders.libero} containerName="chartlibero" />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header fs-4">fLibero holders</div>
              <div className="card-body">
                {dataHolders.flibero && <ChartHolders data={dataHolders.flibero} containerName="chartflibero" />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header fs-4">Libera holders</div>
              <div className="card-body">
                {dataHolders.libera && <ChartHolders data={dataHolders.libera} containerName="chartlibera" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
};
export default Holders;
