import React, { useRef, useEffect } from 'react';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm';

import EpicHero, { typeHero } from '../epicHero/EpicHero';

const rowArmy = function _rowArmy(hero) {
  if (hero instanceof EpicHero) {
    let sumStat = 0;
    if (hero.getInfo) sumStat = Object.values(hero.stat).reduce((total, amount) => total + amount);
    const link = `https://epichero.io/${(hero.type === typeHero.genesis) ? 'hero' : 'demi'}/${hero.ID}`;

    return (
      <tr key={`TR_${hero.ID}`}>
        <th key={`0_${hero.ID}`}><a className="badge rounded-pill bg-primary text-dark has-shadow" href={link}>{hero.ID}</a></th>
        <th key={`1_${hero.ID}`}>{hero.type}</th>
        <th key={`2_${hero.ID}`}>{hero.name}</th>
        <th key={`3_${hero.ID}`}>{hero.className}</th>
        <th key={`4_${hero.ID}`}>{hero.showRarity()}</th>
        <th key={`5_${hero.ID}`}>{hero.level}</th>
        <th key={`6_${hero.ID}`}>{hero.atk}</th>
        <th key={`7_${hero.ID}`}>{sumStat}</th>
        <th key={`8_${hero.ID}`}>{`${hero.value.toFixed(2)}$`}</th>
        <th key={`9_${hero.ID}`}>{hero.task}</th>
      </tr>
    );
  }
  return (null);
};

const Web3ArmyRender = function _Web3ArmyRender(armies) {
  const { troops } = armies;
  const totalValue = useRef(0);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
  }, []);

  const rowHero = React.useMemo(() => {
    totalValue.current = 0;
    const row = troops.map((item) => {
      totalValue.current += parseFloat(item.value);
      return rowArmy(item);
    });
    return row;
  }, [troops]);

  if (troops !== 'undefined' && Array.isArray(troops) && troops.length > 0) {
    return (
      <>
        <table
          className="table table-striped table-hover table-responsive table-sm text-center align-middle"
          style={{ fontSize: '0.8rem' }}
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Type</th>
              <th scope="col">Name</th>
              <th scope="col">ClassName</th>
              <th scope="col">Rarity</th>
              <th scope="col">Level</th>
              <th scope="col">Attack Power</th>
              <th scope="col">Stat</th>
              <th scope="col">
                <span data-bs-toggle="tooltip" data-bs-placement="top" title="calculated from Thoreum current price and evolution price">
                  Estimated price <i className="fas fa-circle-exclamation" style={{ color: 'darkblue' }} />
                </span>
              </th>
              <th scope="col">Task</th>
            </tr>
          </thead>
          <tbody>
            {rowHero}
          </tbody>
        </table>
        <div className="d-flex flex-row-reverse p-4 fw-bold">Estimated value of your army: {totalValue.current.toFixed(2)}$</div>
      </>
    );
  }
  return (
    <div>No result</div>
  );
};

export default Web3ArmyRender;
