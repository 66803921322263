import React from 'react';
import PropTypes from 'prop-types';
import AnyChart from '../anychart';

class ChartHolders extends React.Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    this.containerName = props.containerName;
    this.chart = null;
    this.drawChart();
  }

  shouldComponentUpdate() {
    return false;
  }

  drawChart() {
    const { anychart } = window;
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const drawData = this.data;
    const holdersData = anychart.data.set([
      { x: 'Seashell', value: drawData.seashell, icon: String.fromCodePoint(0x1F41A) },
      { x: 'Snail', value: drawData.snail, icon: String.fromCodePoint(0x1F40C) },
      { x: 'Shrimp', value: drawData.shrimp, icon: String.fromCodePoint(0x1F990) },
      { x: 'Fish', value: drawData.fish, icon: String.fromCodePoint(0x1F41F) },
      { x: 'Octopus', value: drawData.octopus, icon: String.fromCodePoint(0x1F419) },
      { x: 'Squid', value: drawData.squid, icon: String.fromCodePoint(0x1F991) },
      { x: 'Dolphin', value: drawData.dolphin, icon: String.fromCodePoint(0x1F42C) },
      { x: 'Shark', value: drawData.shark, icon: String.fromCodePoint(0x1F988) },
      { x: 'Whale', value: drawData.whale, icon: String.fromCodePoint(0x1F40B) },
      { x: 'Humpback', value: drawData.humpback, icon: String.fromCodePoint(0x1F433) },
    ]);
    // eslint-disable-next-line max-len
    const title = `${drawData.seashell + drawData.snail + drawData.shrimp + drawData.fish + drawData.octopus + drawData.squid + drawData.dolphin + drawData.shark + drawData.whale + drawData.humpback} holders`;

    const chart = anychart.pie(holdersData);
    chart
      .innerRadius('70%')
    // set value for the exploded slices
      .explode(25);
    const label = anychart.standalones.label();
    label
      .enabled(true)
      .text(title)
      .width('100%')
      .height('100%')
      .adjustFontSize(true, true)
      .minFontSize(10)
      .maxFontSize(25)
      .fontColor('#60727b')
      .position('center')
      .anchor('center')
      .hAlign('center')
      .vAlign('middle');

    // set label to center content of chart
    chart.center().content(label);
    chart.title().enabled(false);

    const legend = chart.legend();
    legend.enabled(true);
    legend.itemsLayout('vertical-expandable');
    if (width < 1200) legend.position('bottom');
    else legend.position('right');
    legend.align('center');
    legend.itemsSpacing(5);
    // Set items format.
    legend.itemsFormat(function _() {
      const yvalue = this.getStat('yPercentOfTotal');
      let percent = 0.0;
      if (yvalue) percent = Number.parseFloat(yvalue).toFixed(2);
      return `${this.x} (${percent}%)`;
    });

    chart.labels().position('outside');
    chart.outsideLabelsCriticalAngle(90);

    chart.tooltip().titleFormat('Type of holders: {%x} ({%icon})');
    chart.tooltip().format('Holders: {%Value} ({%PercentValue}{decimalsCount:2}%)');
    chart.autoRedraw(true);

    this.chart = chart;
  }

  render() {
    return <AnyChart instance={this.chart} id={this.containerName} width="100%" height={600} />;
  }
}

export default ChartHolders;

ChartHolders.propTypes = {
  data: PropTypes.objectOf(PropTypes.number).isRequired,
  containerName: PropTypes.string.isRequired,
};
