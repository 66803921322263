import PropTypes from 'prop-types';
import React from 'react';
import AnyChart from '../anychart';
import EpicHero from '../epicHero/EpicHero';
import { EpicHeroCst } from '../epicHero/EpicHeroCst';

class BoxChart extends React.Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    this.containerName = props.containerName;
    this.chart = null;
    this.drawChart();
  }

  shouldComponentUpdate() {
    return false;
  }

  drawChart() {
    const { anychart } = window;
    const drawData = [];
    Object.keys(this.data).forEach((elem) => {
      // console.log(elem);
      const value = this.data[elem];
      const someOption = {};

      const rarity = EpicHero.getValueRarity(elem);
      if (rarity !== -1) {
        const color = EpicHeroCst.color[rarity];
        someOption.fill = `rgb(${color[0]}, ${color[1]},${color[2]})`;
        someOption.stroke = anychart.color.darken(someOption.fill, 0.2);
        someOption.medianStroke = someOption.stroke;
        someOption.stemStroke = someOption.stroke;
        someOption.whiskerStroke = someOption.stroke;
      }
      drawData.push({
        x: elem,
        low: value.whiskerLow,
        q1: value.quartile1,
        median: value.quartile2,
        q3: value.quartile3,
        high: value.whiskerHigh,
        outliers: value.outliers,
        ...someOption,
      });
    });

    const chart = anychart.box();
    chart.xAxis().staggerMode(true);
    chart.xAxis().title('Rarity');
    chart.yAxis().title('sum of statistics');
    chart.yGrid().enabled(true);

    // create box chart series
    const series = chart.box(drawData);
    series.whiskerWidth('50%');

    chart.title().enabled(false);
    this.chart = chart;
  }

  render() {
    return <AnyChart instance={this.chart} id={this.containerName} width="100%" height={800} />;
  }
}

BoxChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]))).isRequired,
  containerName: PropTypes.string.isRequired,
};

export default BoxChart;
