import React from 'react';

const ComingSoon = function _ComingSoon() {
  return (
    <div className="container">
      <h1>Coming soon...</h1>
    </div>
  );
};
export default ComingSoon;
