/* eslint-disable max-classes-per-file */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useGetXLiberaQuery } from '../store/myApiSlice';
import { setError } from '../store/errorSlice';
import AnyChart from './anychart';
// import ChartHolders from './chartHolders';

import CustomLoader from './CustomLoader';

function format(number) {
  return number.toLocaleString('en-US');
}
function showTopWallet(data) {
  return (
    <div className="table-responsive text-center">
      <table className="table table-sm align-middle">
        <thead className="table-light">
          <tr>
            <th className="col">wallet</th>
            <th className="col">locked amount (libero)</th>
            <th className="col">locked period (days)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((element) => (
            <tr key={element.id}>
              <td className="text-start">{element.address}</td>
              <td>{format(element.lockAmount)}</td>
              <td>{moment(element.end).diff(moment(), 'days')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const XLibera = function XLibera() {
  const [topNumber, setTopNumber] = useState(20);
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetXLiberaQuery();

  let content = null;
  let dataHolders;
  if (isLoading) {
    content = <CustomLoader />;
  } else if (isError) {
    dispatch(setError(error.message || error.status));
  } else if (isSuccess && data.status === 'success') {
    dataHolders = data.data;
    const topHolder = dataHolders.top.top.slice(0, topNumber);
    content = (
      <div>soon</div>
      // <div className="flex-container">
      //   <div className="row">
      //     <div className="col-12 mb-4">
      //       <div className="card has-shadow">
      //         <div className="card-header fs-4">
      //           <div>Top {topNumber} of locked value</div>
      //           <div>
      //             <select className="form-select " aria-label="select top number" onChange={(e) => setTopNumber(e.target.value)}>
      //               <option value="10">Top 10</option>
      //               <option value="20" defaultValue>Top 20</option>
      //               <option value="50">Top 50</option>
      //             </select>
      //           </div>
      //         </div>
      //         <div className="card-body">
      //           {showTopWallet(topHolder)}
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="row">
      //     <div className="col-12 mb-4">
      //       <div className="card has-shadow">
      //         {/* <div className="card-header fs-4">xlibero lock time</div> */}
      //         <div className="card-body">
      //           <ChartXLibera data={dataHolders.lockTime.lockTime} containerName="chartxliberolocked" />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="row">
      //     <div className="col-12 mb-4">
      //       <div className="card has-shadow">
      //         {/* <div className="card-header fs-4">xlibero amount locked versus time</div> */}
      //         <div className="card-body">
      //           <ChartXLiberaAmount data={dataHolders.lockAmount} containerName="chartxliberoamount" />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="row">
      //     <div className="col-12 mb-4">
      //       <div className="card has-shadow">
      //         {/* <div className="card-header fs-4">xlibero amount locked versus time</div> */}
      //         <div className="card-body">
      //           <ChartXLiberaPercent data={dataHolders.percent} containerName="chartxliberopercent" />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }

  return content;
};
export default XLibera;

class ChartXLibera extends React.Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    this.containerName = props.containerName;
    this.chart = null;
    this.drawChart();
  }

  shouldComponentUpdate() {
    return false;
  }

  drawChart() {
    const { anychart } = window;
    // const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const drawData = this.data;
    const lockData = anychart.data.set([
      ['<= 2 weeks', drawData[0].data],
      ['<= 1 month', drawData[1].data],
      ['<= 6 months', drawData[2].data],
      ['<= 1 years', drawData[3].data],
      ['<= 4 years', drawData[4].data],
    ]);
    const seriesData = lockData.mapAs({ x: 0, value: 1 });

    const chart = anychart.column();

    chart.title('Number of wallet with locked xLibero vs locked time');
    const legend = chart.legend();
    // Disable crosshair
    legend.enabled(true);
    const series1 = chart.column(seriesData);
    series1.name('number of locked wallet');
    series1.normal().fill('#ffd700', 1);
    series1.hovered().fill('#ffd700', 0.5);
    series1.selected().fill('#ffd700', 0.5);
    series1.normal().stroke('orange', 0);
    series1.hovered().stroke('orange', 2);
    series1.selected().stroke('orange', 4);
    chart.yMinorGrid().enabled(true);
    chart.autoRedraw(true);
    // axes titles
    chart.xAxis().title('period of lock');
    chart.yAxis().title('number of wallet');

    this.chart = chart;
  }

  render() {
    return <AnyChart instance={this.chart} id={this.containerName} width="100%" height={600} />;
  }
}

ChartXLibera.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.number,
    period: PropTypes.number,
  })).isRequired,
  containerName: PropTypes.string.isRequired,
};

class ChartXLiberaAmount extends React.Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    this.containerName = props.containerName;
    this.chart = null;
    this.drawChart();
  }

  shouldComponentUpdate() {
    return false;
  }

  drawChart() {
    const { anychart } = window;
    // const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const drawData = this.data.amount;
    // const drawData = this.data.amount.map((e) => ({
    //   amount: (!e.amount) ? 0 : e.amount,
    //   unlockwallet: (!e.unlockwallet) ? 0 : e.unlockwallet,
    // }));
    const sumAmount = drawData.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0);
    const sumWallet = drawData.reduce((previousValue, currentValue) => previousValue + currentValue.unlockwallet, 0);
    let leftLiquidity = sumAmount;
    let leftWallet = sumWallet;
    const data = [];
    const { period } = this.data;
    const startTime = moment.unix(this.data.time / 1000).toString();
    for (let index = 0; index < drawData.length; index += 1) {
      const element = drawData[index];
      leftLiquidity -= element.amount;
      leftWallet -= element.unlockwallet;
      // console.log(index * period);
      data.push([moment(startTime).add(index * period, 'days').format('YYYY-MM-DD'), leftLiquidity / 1e6, leftWallet]);
    }

    const lockData = anychart.data.set([...data]);
    const seriesData1 = lockData.mapAs({ x: 0, value: 1 });
    const seriesData2 = lockData.mapAs({ x: 0, value: 2 });

    const chart = anychart.area();
    const legend = chart.legend();
    // Disable crosshair
    legend.enabled(true);

    const extraYScale = anychart.scales.linear();
    chart.xScale().mode('continuous');
    chart.xAxis().labels().rotation(-45);
    // chart.xAxis().staggerMode(true);
    chart.title('Number of locked wallet vs locked period');

    const series1 = chart.area(seriesData1);
    series1.name('liquidity left (M libero)');
    series1.normal().fill(['0.1 blue 0.5', '.9 darkblue 0.3'], 90);
    // series1.hovered().fill('#00cc99', 0.1);
    // series1.selected().fill('#00cc99', 0.5);
    series1.normal().stroke('blue', 2);
    // series1.hovered().stroke('#00cc99', 2, '', 'round');
    // series1.selected().stroke('#00cc99', 4, '', 'round');

    const series2 = chart.line(seriesData2);
    series2.yScale(extraYScale);
    series2.name('number of locked wallet');
    chart.yAxis(1).stroke('darkorange');
    chart.yAxis(1).scale(extraYScale);
    chart.yAxis(1).enabled(true);
    chart.yAxis(1).orientation('right');

    // series2.normal().fill('orange', 0.2);
    // series2.hovered().fill('orange', 0.1);
    // series2.selected().fill('orange', 0.5);
    series2.normal().stroke('orange', 3);
    // series2.hovered().stroke('orange', 2, '', 'round');
    // series2.selected().stroke('orange', 4, '', 'round');

    // create DateTime scale
    const dateScale = anychart.scales.dateTime();
    // set one year ticks interval
    const dateTicks = dateScale.ticks();
    dateTicks.interval(0, 6);
    // set two months minor ticks interval
    const dateMinorTicks = dateScale.minorTicks();
    dateMinorTicks.interval(0, 2);
    chart.xScale(dateScale);

    chart.xMinorGrid().enabled(true);
    chart.yGrid().enabled(true);

    chart.autoRedraw(true);
    // axes titles
    chart.xAxis().title('locked period');
    chart.yAxis(1).title('wallet number');
    chart.yAxis(0).title('liquidity (Million Libero)');

    this.chart = chart;
  }

  render() {
    return <AnyChart instance={this.chart} id={this.containerName} width="100%" height={600} />;
  }
}
ChartXLiberaAmount.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.arrayOf(PropTypes.shape({
      unlockwallet: PropTypes.number,
      amout: PropTypes.number,
    })),
    period: PropTypes.number,
    time: PropTypes.number,
  }).isRequired,
  containerName: PropTypes.string.isRequired,
};
class ChartXLiberaPercent extends React.Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    this.containerName = props.containerName;
    this.chart = null;
    this.drawChart();
  }

  shouldComponentUpdate() {
    return false;
  }

  drawChart() {
    const { anychart } = window;
    // const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const drawData = this.data.percent;
    const holdersData = anychart.data.set([
      { x: 'wallet without xlibero', value: drawData.total, fill: '#ffd700' },
      { x: 'wallet with xlibero', value: drawData.locked, fill: 'darkorange' },
    ]);
    const chart = anychart.pie(holdersData);
    // chart.normal({ fill: '#ffd700' });
    // chart.selected({ fill: 'darkorange' });
    chart.title('Percentage of wallet with locked libero');
    const labels = chart.labels();
    labels.fontSize(16);
    labels.width(80);
    labels.height(40);
    labels.background().enabled(true);
    labels.background().fill('Black 0.4');
    labels.vAlign('middle');
    labels.hAlign('center');
    chart.select([1]);
    this.chart = chart;
  }

  render() {
    return <AnyChart instance={this.chart} id={this.containerName} width="100%" height={600} />;
  }
}
ChartXLiberaPercent.propTypes = {
  data: PropTypes.shape({
    percent: PropTypes.shape({
      total: PropTypes.number,
      locked: PropTypes.number,
    }),
    time: PropTypes.number,
  }).isRequired,
  containerName: PropTypes.string.isRequired,
};
