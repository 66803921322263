import React from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import { useGetInfoBattlesQuery } from '../../store/myApiSlice';
import CustomLoader from '../CustomLoader';
import { setError } from '../../store/errorSlice';

function someInfo(epoch) {
  let myDate;
  if (!epoch) myDate = new Date(0);
  else myDate = new Date(epoch);
  return (
    <div className="card has-shadow">
      <div className="card-header fs-3">Some information</div>
      <div className="card-body p-1">
        <ul>
          <li>All statistics are compute every 6h</li>
          <li>{`the last update was at ${myDate.toLocaleString()}`}</li>
          <li>The conversion in Dollard is computed with 17% tax fees, slippage of 5% and BSC fee of 0.35$</li>
        </ul>
      </div>
    </div>
  );
}

function dungeonStats(type, data) {
  // const localData = { ...data, id: null };
  const tabDungeon = [
    'demi small 1',
    'demi small 2',
    'demi small 3',
    'demi medium 1',
    'demi medium 2',
    'demi medium 3',
    'demi large 1',
    'demi large 2',
    'demi large 3',
    'genesis small 1',
    'genesis small 2',
    'genesis small 3',
    'genesis medium 1',
    'genesis medium 2',
    'genesis medium 3',
    'genesis large 1',
    'genesis large 2',
    'genesis large 3',
  ];
  let txt = '';
  if (type === 'last') {
    txt = `on last ${data.period} days`;
  } else if (type === 'yesterday') {
    txt = 'yesterday';
  } else if (type === 'today') {
    txt = 'for today';
  }

  return (
    <div className="card has-shadow">
      <div className="card-header fs-3">{`Dungeon rewards ${txt}`}</div>
      <div className="card-body p-1">
        <div className="table-responsive">
          <table className="table table-striped table-sm align-middle text-nowrap">
            <thead>
              <tr>
                <td className="text-center font-weight-bold">Dungeon</td>
                <td className="text-center font-weight-bold">Rewards (EH)</td>
                <td className="text-center font-weight-bold">Rewards (Libero)</td>
                <td className="text-center font-weight-bold">Net Rewards ($)</td>
              </tr>
            </thead>
            <tbody>
              {data.battle.map((element, index) => {
                if (element) {
                  return (
                    <tr key={nanoid(6)}>
                      <td>{tabDungeon[index]}</td>
                      <td className="text-center">{element.EHRewards}</td>
                      <td className="text-center">{element.liberoRewards}</td>
                      <td className="text-center">{element.nofeeRewards}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
const HeroStats = function HeroStats() {
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetInfoBattlesQuery();

  let content = null;
  let dataStat;
  if (isLoading) {
    content = <CustomLoader />;
  } else if (isError) {
    dispatch(setError(error.message || error.status));
  } else if (isSuccess && data.status === 'success') {
    dataStat = data.data;

    content = (
      <div className="flex-container ">
        <div className="row">
          <div className="col-12 mb-5">
            {someInfo(dataStat.time)}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 mb-5">
            {dungeonStats('last', dataStat.data10j)}
          </div>
          <div className="col-xl-6 mb-5">
            {dungeonStats('last', dataStat.data2j)}
          </div>
          <div className="col-xl-6 mb-5">
            {dungeonStats('yesterday', dataStat.dataYesterday)}
          </div>
          <div className="col-xl-6 mb-5">
            {dungeonStats('today', dataStat.dataToday)}
          </div>
        </div>
      </div>
    );
  }
  return content;
};

export default HeroStats;
