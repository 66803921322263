export const EpicHeroCst = {
  // pool genesis
  genesisDailyTaskAddr: '0x035a2aa245950653caa1514df7A71c215200Abaa',
  // pool demi
  demiDailyTaskAddr: '0x670a0AF554b349020b8b3909996a248F06540eb1',
  dailyTaskAbi: [
    'function getMultiplier(uint256 _from, uint256 _to) public pure returns (uint256)',
    'function pendingEpicHero(uint256 _pid, address _user) external view returns (uint256)',
    'function canHarvest(uint256 _pid, address _user) public view returns (bool)',
    'function getNftStaking(address _user) external view returns (uint256[] memory)',
    'function getNftOwner(uint256 _heroId) external view returns (address)',
    'function countNftInPool(uint256 _pid, address _user) public view returns (uint256)',
    'function getNftInPool(uint256 _pid, address _user) public view returns (uint256[] memory)',

    'function poolLength() public view returns (uint256 nbpool)',
    'event Deposit(address indexed user, uint256 indexed pid, uint256 amount)',
    'event DepositNFT(address indexed user, uint256 indexed pid, uint256[] heroIds)',
    'event Withdraw(address indexed user, uint256 indexed pid, uint256 amount)',
    'event WithdrawNFT(address indexed user, uint256 indexed pid, uint256[] heroIds)',
    'event EmergencyWithdraw(address indexed user, uint256 indexed pid, uint256 amount)',
    'event EmissionRateUpdated(address indexed caller, uint256 previousAmount, uint256 newAmount)',
    'event ReferralCommissionPaid(address indexed user, address indexed referrer, uint256 commissionAmount)',
    'event RewardLockedUp(address indexed user, uint256 indexed pid, uint256 amountLockedUp)',
  ],
  // olympe
  OlympeAddr: '0xe798079cdBe8C6c922f240b55DE51403CB9E30E2',
  OlympeAbi: [
    'function getNftStaking(address _user) external view returns (uint256[])',
    'function getNftStaking2(address _user) external view returns (uint256[])',
    'event DepositNFT(address indexed user, uint256 indexed pid, uint256[] heroIds, uint256[] heroIds2)',
    'event WithdrawNFT(address indexed user, uint256 indexed pid, uint256[] heroIds, uint256[] heroIds2)',
    'event JoinPool(address indexed user, uint256 indexed pid)',
    'event EmissionRateUpdated(address indexed caller, uint256 previousAmount, uint256 newAmount)',
    'event ReferralCommissionPaid(address indexed user, address indexed referrer, uint256 commissionAmount)',
  ],
  // demi
  EpicHeroNFTDemiAddr: '0xDD581CAb6F7643AB11498a4B83a8bcDA9EACa29A',
  EpicHeroNFTGenesisAddr: '0xafDcB0eCaD1c8Cb22893dCA7D6c510dBFDa3BBeC',
  EpicHeroNFTAbi: [
    'function getHero(uint256 heroId) public view returns (uint8 level, uint8 rarity)',
    'function balanceOf(address owner) external view returns (uint balance)',
    'function tokenOfOwnerByIndex(address owner, uint index) external view returns (uint tokenId)',
    'event CardMinted(address recipient, uint heroId, uint8 packId)',
    'event CardSetAdded(uint setId, uint64 mintLimit)',
    'event CardSetEdited(uint setId, uint64 mintLimit)',
    'event PackPurchased(address user, uint8 packId)',
    'event PackAdded(uint packId, uint basePrice, uint basePrice2, uint8 numOfCards, uint8 cardSetId, address tokenAddress, address tokenAddress2)',
    'event PackEdited(uint packId, uint basePrice, uint basePrice2, uint8 numOfCards, bool saleRunning, uint8 cardSetId, address tokenAddress, address tokenAddress2)',
    'event AttributeAdded(uint id, string name)',
    'event AttributeEdited(uint id, string name)',
    'event AttributeChanged(uint heroId, string attributeName, string newValue)',
    'event AdminKillHero(uint indexed heroId)',
    'event AdminSetLevel(uint indexed heroId, uint8 newLevel)',
    'event UserLevelUp(uint indexed heroId, uint8 newLevel)',
    'event WaitingLevelUp(uint indexed heroId, uint8 newLevel)',
  ],
  Addr: '0x47cc5334f65611ea6be9e933c49485c88c17f5f0',
  color: [
    [0, 0, 0],
    [189, 147, 101],
    [0, 146, 69],
    [23, 210, 227],
    [123, 28, 148],
    [225, 133, 26],
    [197, 49, 57],
    [193, 0, 161],
  ],
};

export const MarketPlace = {
  AddrGenesis: '0xCa919EcAB8a88428ef1C1c7BB0509cDeCE5DC4dB', // '0x01969585AC26f4dbbCD2417Fbf1cb309c05098F6',
  AddrDemi: '0x56D6B3f0Cb98ba9373b56b9784a043AF910C9D9A', // '0x30E3845C178e74A90DcF5726cc18897F0F8D8d15',
  Abi: [
    // 'event Authorized(address adr)',
    // 'event OfferCanceled(uint256 indexed tokenId, address sender)',
    // 'event OfferOpened(uint256 indexed tokenId, address seller, address buyer, uint256 price)',
    // 'event OfferTaken(uint256 indexed tokenId, address seller, address buyer, uint256 price)',
    // 'event OwnershipTransferred(address owner)',
    // 'event SendWbnbDividends(uint256 amount)',
    // 'event TradeCancelled(uint256 tradeId, address indexed seller, uint256 indexed tokenId, uint256 price)',
    // 'event TradeClosed(uint256 tradeId, address indexed seller, address indexed buyer, uint256 indexed tokenId, uint256 price)',
    // 'event TradeOpened(uint256 tradeId, address indexed seller, uint256 indexed tokenId, uint256 price)',
    // 'event Unauthorized(address adr)',

    // 'function authorize(address adr)',
    // 'function cancelOffer(uint24 tokenId)',
    // 'function cancelTrade(uint256 tradeId)',
    // 'function countOpenTradeByOwner(address seller) view returns (uint256)',
    // 'function dexRouter() view returns (address)',
    // 'function emergencyReturnNfts()',
    'function executeTrade(uint256 tradeId)',
    // 'function feeWallet() view returns (address)',
    // 'function getAllTrades() view returns (tuple(uint24 id, uint24 tokenId, uint8 status, uint256 tokenPrice, address seller, address buyer)[])',
    'function getOpenTradeByToken(uint24 tokenId) view returns (tuple(uint24 id, uint24 tokenId, uint8 status, uint256 tokenPrice, address seller, address buyer))',
    'function getOpenTrades() view returns (tuple(uint24 id, uint24 tokenId, uint8 status, uint256 tokenPrice, address seller, address buyer)[])',
    'function getOpenTradesLength() view returns (uint256)',
    // 'function getOpenTradesSlice(uint256 start, uint256 end) view returns (tuple(uint24 id, uint24 tokenId, uint8 status, uint256 tokenPrice, address seller, address buyer)[])',
    // 'function getOpensTradeByOwner(address seller) view returns (tuple(uint24 id, uint24 tokenId, uint8 status, uint256 tokenPrice, address seller, address buyer)[])',
    // 'function getTrade(uint256 tradeId) view returns (tuple(uint24 id, uint24 tokenId, uint8 status, uint256 tokenPrice, address seller, address buyer))',
    // 'function getTradeCount() view returns (uint256)',
    // 'function isAuthorized(address adr) view returns (bool)',
    'function isClosed() view returns (bool)',
    // 'function isOwner(address account) view returns (bool)',
    // 'function minPriceAtRarity(uint8) view returns (uint256)',
    // 'function minRarity() view returns (uint8)',
    // 'function nftAddress() view returns (address)',
    // 'function onERC721Received(address, address, uint256, bytes) pure returns (bytes4)',
    // 'function openOffer(uint24 tokenId, uint256 offerValue)',
    // 'function openTrade(uint24 tokenId, uint256 price)',
    // 'function retrieveBNB(uint256 amount)',
    // 'function retrieveTokens(address _token, uint256 amount)',
    // 'function setCloseMarket(bool value)',
    // 'function setDexRouter(address newAddress)',
    // 'function setMinPriceAtRarity(uint8 rarity, uint256 minPrice)',
    // 'function setMinRarity(uint8 rarity)',
    // 'function setSwapTokensAtAmount(uint256 _swapAmount)',
    // 'function setTradeFee(uint256 newFee)',
    // 'function setWbnbReflectRewardsFee(uint256 newFee)',
    // 'function setWbnbReflectToken(address _newContract)',
    // 'function setWbnbReflectTracker(address _newContract)',
    // 'function swapTokensAtAmount() view returns (uint256)',
    // 'function takeOffer(uint24 tokenId, address buyer)',
    // 'function tokenAddress() view returns (address)',
    // 'function tokensWithOffers(uint24, address) view returns (uint256)',
    // 'function tradeFee() view returns (uint256)',
    // 'function transferOwnership(address adr)',
    // 'function unauthorize(address adr)',
    // 'function wbnbReflectRewardsFee() view returns (uint256)',
    // 'function wbnbReflectToken() view returns (address)',
    // 'function wbnbReflectTracker() view returns (address)',
  ],
};

export const ThoreumCst = {
  // Addr: '0x580de58c1bd593a43dadcf0a739d504621817c05',
  Addr: '0xF2A92BC1Cf798fF4De14502a9C6FdA58865e8D5d',
};

export const ThoreumV2 = {
  Addr: '0xF2A92BC1Cf798fF4De14502a9C6FdA58865e8D5d',
  ABI: [
    'event Approval(address indexed owner, address indexed spender, uint256 value)',
    'event ExcludeFromFees(address indexed account, bool isExcluded)',
    'event GenericErrorEvent(string reason)',
    'event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)',
    'event SendBusdDividends(uint256 amount)',
    'event SetAutomatedMarketMakerPair(address indexed pair, bool indexed value)',
    'event SwapAndLiquify(uint256 tokensSwapped, uint256 bnbReceived, uint256 tokensIntoLiqudity)',
    'event SwapAndLiquifyBusd(uint256 tokensSwapped, uint256 busdReceived, uint256 tokensIntoLiqudity)',
    'event Transfer(address indexed from, address indexed to, uint256 value)',
    // 'function MAX_FEE_RATE() view returns (uint256)',
    // 'function MAX_SUPPLY() view returns (uint256)',
    'function allowance(address owner, address spender) view returns (uint256)',
    // 'function approve(address spender, uint256 amount) returns (bool)',
    // 'function automatedMarketMakerPairs(address) view returns (bool)',
    // 'function balanceOf(address account) view returns (uint256)',
    // 'function busdDividendRewardsFee() view returns (uint256)',
    // 'function busdDividendToken() view returns (address)',
    // 'function busdDividendTracker() view returns (address)',
    // 'function claim()',
    // 'function compound()',
    // 'function decimals() view returns (uint8)',
    // 'function decreaseAllowance(address spender, uint256 subtractedValue) returns (bool)',
    // 'function dexRouter() view returns (address)',
    // 'function excludeFromDividend(address account, bool status)',
    // 'function excludeFromFees(address account, bool excluded)',
    // 'function feesOnNormalTransfers() view returns (bool)',
    // 'function increaseAllowance(address spender, uint256 addedValue) returns (bool)',
    // 'function isExcludedFromFees(address) view returns (bool)',
    // 'function isLiquidityInBnb() view returns (bool)',
    // 'function isNotMigrating() view returns (bool)',
    // 'function liquidityFee() view returns (uint256)',
    // 'function lock(uint256 time)',
    // 'function marketingFee() view returns (uint256)',
    // 'function marketingWallet() view returns (address)',
    // 'function maxBuyTransactionAmount() view returns (uint256)',
    // 'function maxSellTransactionAmount() view returns (uint256)',
    // 'function maxWalletToken() view returns (uint256)',
    // 'function name() view returns (string)',
    // 'function owner() view returns (address)',
    // 'function prepareForPartner(address _partnerOrExchangeAddress)',
    // 'function renounceOwnership()',
    // 'function retrieveBNB()',
    // 'function retrieveTokens(address token)',
    // 'function sellFeeIncreaseFactor() view returns (uint256)',
    // 'function setAutomatedMarketMakerPair(address pair, bool value)',
    // 'function setBusdDividendRewardFee(uint256 newFee)',
    // 'function setBusdDividendToken(address _newContract)',
    // 'function setBusdDividendTracker(address newAddress)',
    // 'function setFeesOnNormalTransfers(bool _enabled)',
    // 'function setIsLiquidityInBnb(bool _value)',
    // 'function setIsNotMigrating(bool _value)',
    // 'function setLiquidityFee(uint256 newFee)',
    // 'function setMarketingFee(uint256 newFee)',
    // 'function setMarketingWallet(address _newWallet)',
    // 'function setMaxBuyTransaction(uint256 _maxTxn)',
    // 'function setMaxSellTransaction(uint256 _maxTxn)',
    // 'function setMaxWalletToken(uint256 _maxToken)',
    // 'function setMinimumBalanceForDividends(uint256 newMinimumBalance)',
    // 'function setSellTransactionMultiplier(uint256 _multiplier)',
    // 'function setSwapTokensAtAmount(uint256 _swapAmount)',
    // 'function setToBurnTokenFee(uint256 newFee)',
    // 'function setTreasuryFee(uint256 newFee)',
    // 'function setTreasuryWallet(address _newWallet)',
    // 'function swapTokensAtAmount() view returns (uint256)',
    // 'function symbol() view returns (string)',
    // 'function toBurnTokenFee() view returns (uint256)',
    // 'function totalFees() view returns (uint256)',
    // 'function totalSupply() view returns (uint256)',
    // 'function totalTreasuryAmount() view returns (uint256)',
    // 'function transfer(address recipient, uint256 amount) returns (bool)',
    // 'function transferFrom(address sender, address recipient, uint256 amount) returns (bool)',
    // 'function transferOwnership(address newOwner)',
    // 'function treasuryFee() view returns (uint256)',
    // 'function treasuryWallet() view returns (address)',
  ],
};

export const MCVCst = {
  Addr: '0x80d04E44955AA9c3F24041B2A824A20A88E735a8',
};

export const LiberoCst = {
  Addr: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
};

export const ERC20Generic = {
  Abi: [
    'function name() view returns (string)',
    'function approve(address _spender, uint256 _value) returns (bool)',
    'function totalSupply() view returns (uint256)',
    'function transferFrom(address _from, address _to, uint256 _value) returns (bool)',
    'function decimals() view returns (uint8)',
    'function balanceOf(address _owner) view returns (uint256 balance)',
    'function symbol() view returns (string)',
    'function transfer(address _to, uint256 _value) returns (bool)',
    'function allowance(address _owner, address _spender) view returns (uint256)',
    'event Approval(address indexed owner, address indexed spender, uint256 value)',
    'event Transfer(address indexed from, address indexed to, uint256 value)',
  ],
};
