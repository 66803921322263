import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

const initialState = {
  isError: false,
  message: [],
};

export const errorSlice = createSlice({
  name: 'mainError',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.isError = true;
      state.message.push({ id: nanoid(), type: 'error', message: action.payload });
    },
    setErrorNoProvider: (state) => {
      state.isError = false;
      state.message.push({ id: nanoid(), type: 'warning', message: 'No provider found, please install metamask' });
    },
    setWarning: (state, action) => {
      state.message.push({ id: nanoid(), type: 'warning', message: action.payload });
    },
    cleanMsg: (state, action) => {
      state.isError = false;
      const id = action.payload;
      const index = state.message.findIndex((element) => element.id === id);
      if (index !== -1) {
        state.message.splice(index, 1);
      }
    },
  },
});
export default errorSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setError, cleanMsg, setWarning, setErrorNoProvider,
} = errorSlice.actions;
