import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  account: null,
  active: false,
  chainId: null,
  blockNumber: 0,
};

export const web3Slice = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.account = action.payload.account;
      state.chainId = action.payload.chainId;
      state.blockNumber = action.payload.blockNumber;
      state.active = Boolean(action.payload.account && action.payload.chainId === 56 && action.payload.blockNumber > 0);
    },
    cleanProvider: (state) => {
      state.active = false;
      state.chainId = null;
      state.account = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  cleanProvider, setLogin,
} = web3Slice.actions;

export default web3Slice.reducer;
