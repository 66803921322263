import React from 'react';
import { BallTriangle } from 'react-loader-spinner';

const CustomLoader = function CustomLoader() {
  return (
    <div className="col-lg-4 col-md-4 col-sm-4 mb-5 container-fluid justify-content-center text-center">
      <h2>Loading...</h2>
      <div className="d-flex justify-content-center">
        <BallTriangle
          color="gold"
          height={150}
          width={150}
        />
      </div>
    </div>
  );
};
export default CustomLoader;
