import { ethers } from 'ethers';
// import connectWeb3 from '../wallet/connectWeb3';

import EpicHero, { typeHero } from '../epicHero/EpicHero';
import { EpicHeroCst } from '../epicHero/EpicHeroCst';
import { getProvider } from '../wallet/ether';

const Web3army = async function Web3army(account) {
  const Data = {
    data: null,
    err: null,
  };

  async function getHeroDailyTasks(provider, addr, type) {
    const EHContract = new ethers.Contract(addr, EpicHeroCst.dailyTaskAbi, provider);
    const getNumberDemiPool = await EHContract.poolLength();

    let res = [];
    for (let index = 0; index < getNumberDemiPool.toNumber(); index += 1) {
      res.push(EHContract.getNftInPool(index, account));
    }
    res = await Promise.allSettled(res);

    const HeroId = [];
    for (let index = 0; index < res.length; index += 1) {
      const e = res[index];
      if (e.status === 'fulfilled') {
        e.value.map((item) => HeroId.push({ ID: item.toNumber(), Daily: `DailyTask#${index + 1}` }));
      }
    }
    const hero = await EpicHero.getHeroInfo(HeroId, provider, type);
    Data.data.push(...hero);
  }
  async function getHeroOlympus(provider) {
    const OlympusContract = new ethers.Contract(EpicHeroCst.OlympeAddr, EpicHeroCst.OlympeAbi, provider);

    const olympe = [
      {
        contract: OlympusContract.getNftStaking(account),
        type: typeHero.genesis,
      },
      {
        contract: OlympusContract.getNftStaking2(account),
        type: typeHero.demi,
      }];

    for (let j = 0; j < olympe.length; j += 1) {
      // eslint-disable-next-line no-await-in-loop
      const res = await olympe[j].contract;

      const HeroId = [];
      for (let index = 0; index < res.length; index += 1) {
        HeroId.push({ ID: res[index].toNumber(), Daily: 'Olympus' });
      }
      // eslint-disable-next-line no-await-in-loop
      const hero = await EpicHero.getHeroInfo(HeroId, provider, olympe[j].type);
      Data.data.push(...hero);
    }
  }
  async function getHeroFree(provider) {
    const freeContract = new ethers.Contract(EpicHeroCst.EpicHeroNFTGenesisAddr, EpicHeroCst.EpicHeroNFTAbi, provider);
    const freeContract2 = new ethers.Contract(EpicHeroCst.EpicHeroNFTDemiAddr, EpicHeroCst.EpicHeroNFTAbi, provider);

    const free = [
      {
        balanceContract: freeContract.balanceOf(account),
        type: typeHero.genesis,
        contract: freeContract,
      },
      {
        balanceContract: freeContract2.balanceOf(account),
        type: typeHero.demi,
        contract: freeContract2,
      }];

    for (let j = 0; j < free.length; j += 1) {
      // eslint-disable-next-line no-await-in-loop
      const nbHeroes = await free[j].balanceContract;

      let res = [];
      for (let index = 0; index < nbHeroes.toNumber(); index += 1) {
        res.push(free[j].contract.tokenOfOwnerByIndex(account, index));
      }
      // eslint-disable-next-line no-await-in-loop
      res = await Promise.allSettled(res);

      const HeroId = [];
      for (let index = 0; index < res.length; index += 1) {
        const e = res[index];
        if (e.status === 'fulfilled') {
          HeroId.push({ ID: e.value.toNumber(), Daily: 'free' });
        }
      }
      // eslint-disable-next-line no-await-in-loop
      const hero = await EpicHero.getHeroInfo(HeroId, provider, free[j].type);
      Data.data.push(...hero);
    }
  }

  try {
    const provider = await getProvider();
    const res = [];
    let contracts = null;
    // get info dailytask
    contracts = [
      { addr: EpicHeroCst.demiDailyTaskAddr, type: typeHero.demi },
      { addr: EpicHeroCst.genesisDailyTaskAddr, type: typeHero.genesis },
    ];
    for (let index = 0; index < contracts.length; index += 1) {
      const cont = contracts[index];
      res.push(getHeroDailyTasks(provider, cont.addr, cont.type));
    }
    // end
    // get info olympus
    res.push(getHeroOlympus(provider));
    // end
    // get info free hero
    res.push(getHeroFree(provider));
    // end
    Data.data = [];
    await Promise.all(res);
  } catch (error) {
    console.error(error);
    Data.err = error.message;
  }

  return Data;
};

export default Web3army;
