import React from 'react';
import PropTypes from 'prop-types';
import { secondsToHms } from './utility';

class Clock extends React.Component {
  constructor(props) {
    super(props);
    const { isCounter, counter } = this.props;
    this.state = {
      time: new Date(),
      isCounter,
      counter,
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      time: new Date(),
    });
  }

  render() {
    const { time, isCounter, counter } = this.state;
    if (!isCounter) {
      return (
        <span>{time.toLocaleString()}</span>
      );
    }
    return <span>{secondsToHms((time - counter) / 1000)}</span>;
  }
}

export default Clock;

Clock.propTypes = {
  isCounter: PropTypes.bool,
  counter: PropTypes.number,
};
Clock.defaultProps = {
  isCounter: false,
  counter: 0,
};
