import React from 'react';
import useWeb3 from './ether';

function showAddr(address) {
  return `${address.substring(0, 6)}...${address.substring(36)}`;
}
function showShortAddr(address) {
  return `${address.substring(0, 4)}`;
}

const WalletButton = function _WalletButton() {
  const {
    account, active, login, logout,
  } = useWeb3();

  async function connectEthereum() {
    await login();
  }
  function disconnectEthereum() {
    logout();
    window.location.reload();
  }

  return (
    <button
      type="button"
      className="btn btn-outline-primary py-md-0"
      onClick={() => {
        if (!active) {
          connectEthereum();
        } else {
          disconnectEthereum();
        }
      }}
    >
      <i className="fas fa-wallet" />
      {'  '}
      <span>{!account && 'Connect Wallet'}</span>
      <span className="">{account && showAddr(account)}</span>
    </button>
  );
};

export default WalletButton;
