import { useCallback, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useSelector, useDispatch } from 'react-redux';
import { setLogin, cleanProvider } from '../../store/web3';
import { setErrorNoProvider } from '../../store/errorSlice';

const useAuth = () => {
  const dispatch = useDispatch();

  const login = useCallback(async () => {
    let providertmp = null;
    if (!window.ethereum) {
      dispatch(setErrorNoProvider());
      return;
    }

    providertmp = new ethers.providers.Web3Provider(window.ethereum);
    await providertmp.ready;

    const prov = {};
    prov.blockNumber = await providertmp.getBlockNumber();
    await providertmp.send('eth_requestAccounts', []);
    const signer = providertmp.getSigner();
    prov.account = await signer.getAddress();
    const network = await providertmp.getNetwork();
    prov.chainId = network.chainId;
    dispatch(setLogin(prov));
  }, [dispatch]);

  const logout = useCallback(() => {
    cleanProvider();
  }, []);

  return { login, logout };
};

export const getProvider = async () => {
  let provider = null;
  if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.StaticJsonRpcProvider('https://bsc-dataseed.binance.org');
  }
  await provider.ready;
  return provider;
};

export const useTokenBalance = (tokenAddress, account) => {
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    const getBalance = async () => {
      const provider = await getProvider();
      const ERC20Abi = [
        'function balanceOf(address) view returns (uint)',
      ];
      const tokenContract = new ethers.Contract(tokenAddress, ERC20Abi, provider);
      const tokenBal = await tokenContract.balanceOf(account);
      setBalance(ethers.utils.formatEther(tokenBal));
    };
    if (tokenAddress && account) getBalance();
  }, [account, tokenAddress]);

  return balance;
};

const useWeb3 = () => {
  const {
    account, active, chainId, blockNumber,
  } = useSelector((state) => state.web3);
  const { login, logout } = useAuth();

  return {
    account, active, chainId, blockNumber, login, logout, getProvider,
  };
};
export default useWeb3;
