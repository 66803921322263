import React from 'react';

const Footer = function _Footer() {
  return (
    <div className="footer">
      <footer className="d-grid m-0 p-0 text-center w-100 position-absolute bottom-0 start-50 translate-middle-x">
        <div className="row address"><small>you can donnate here: <strong>0xDcf7026b81Be0E68a6563C2F101BB9cEb2B366F4</strong></small></div>
        <div className="row mb-2">
          <small>
            © 2021-2022 made with
            {' '}
            <i className="fas fa-robot" style={{ color: 'purple' }} />
            {' by '}
            <a className="link-info" href="https://t.me/Grutorel" target="_blank" rel="noreferrer">Grutorel</a>
          </small>
        </div>
        <br />
      </footer>
    </div>
  );
};
export default Footer;
