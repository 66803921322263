import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { DAppProvider, ChainId } from '@usedapp/core';

import store from './store/store';
import App from './App';

import './custom.scss';
import './layout.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root'),
);
