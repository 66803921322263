import React from 'react';

const ErrorPage = function _ErrorPage() {
  return (
    <div className="container">
      <h1>Oupss</h1>
      <h2>We couldn&apos;t find what you were looking for</h2>
    </div>
  );
};
export default ErrorPage;
