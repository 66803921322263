import React from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import { setError } from '../store/errorSlice';
import { useGetEHStatsQuery, useGetInfoHeroQuery } from '../store/myApiSlice';
import Clock from './Clock';
import CustomLoader from './CustomLoader';
import PieCHart from './PieChart';
import EpicHero from './epicHero/EpicHero';

const createboxstate = (title, _value, type) => {
  let value = '';
  if (type === 'price') {
    value = `${parseFloat(_value).toFixed(6)}$`;
  } else if (type === 'prices') {
    value = `Thoreum: ${_value[0]}$  EpicHero: ${_value[1]}$ MVC: ${_value[2]}$`;
  } else if (type === 'epoch') {
    value = <Clock isCounter counter={_value} />;
  } else {
    value = _value;
  }

  return (
    <div className="stats">
      <div className="row bg-white has-shadow p-2 rounded">
        <div className="left-col col-md-4 d-flex align-items-center align-middle border-end border-2 justify-content-end">
          {title}
        </div>
        <div className="right-col col-md-8 d-flex align-items-center justify-content-end">
          {value}
        </div>
      </div>
    </div>
  );
};

function DrawChart() {
  const dispatch = useDispatch();
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetInfoHeroQuery();

  let content = null;
  if (isLoading) {
    content = <CustomLoader />;
  } else if (isError) {
    dispatch(setError(error.message || error.status));
  } else if (isSuccess && data.status === 'success') {
    const { data: gdata } = data;
    const heroes = {};
    heroes.demi = gdata.demi;
    heroes.genesis = gdata.genesis;
    const heroesDrawing = [
      { x: 'Demi heroes', value: (heroes.demi.total - heroes.demi.dead), fill: 'Orange' },
      { x: 'Dead Demi heroes', value: heroes.demi.dead, fill: 'OrangeRed' },
      { x: 'Genesis heroes', value: (heroes.genesis.total - heroes.genesis.dead), fill: 'Turquoise' },
      { x: 'Dead Genesis heroes', value: heroes.genesis.dead, fill: 'DarkTurquoise' },
    ];

    const ElementDrawing = [];
    Object.keys(gdata.element).forEach((item) => {
      ElementDrawing.push([EpicHero.getElement(item), gdata.element[item]]);
    });

    const ClassDrawing = [];
    Object.keys(gdata.className).forEach((item) => {
      ClassDrawing.push([EpicHero.getClassName(item), gdata.className[item]]);
    });
    const DemiLevelDrawing = [];
    Object.keys(gdata.demi.level).forEach((item) => {
      DemiLevelDrawing.push([item, gdata.demi.level[item]]);
    });
    const DemiRarityDrawing = [];
    Object.keys(gdata.demi.rarity).forEach((item) => {
      DemiRarityDrawing.push([EpicHero.getRarity(item), gdata.demi.rarity[item]]);
    });
    const GenesisLevelDrawing = [];
    Object.keys(gdata.demi.level).forEach((item) => {
      GenesisLevelDrawing.push([item, gdata.demi.level[item]]);
    });
    const GenesisRarityDrawing = [];
    Object.keys(gdata.genesis.rarity).forEach((item) => {
      GenesisRarityDrawing.push([EpicHero.getRarity(item), gdata.genesis.rarity[item]]);
    });
    content = (
      <>
        <div className="row">
          <div className="col-xl-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Heroes</div>
              <div className="card-body">
                <PieCHart data={heroesDrawing} containerName="heroesPie" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Element</div>
              <div className="card-body">
                <PieCHart data={ElementDrawing} containerName="ElementPie" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Class</div>
              <div className="card-body">
                <PieCHart data={ClassDrawing} containerName="ClassPie" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Demi Level</div>
              <div className="card-body">
                <PieCHart data={DemiLevelDrawing} containerName="DLPie" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Genesis level</div>
              <div className="card-body">
                <PieCHart data={GenesisLevelDrawing} containerName="GLPie" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Demi Rarity</div>
              <div className="card-body">
                <PieCHart data={DemiRarityDrawing} containerName="DRPie" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Genesis Rarity</div>
              <div className="card-body">
                <PieCHart data={GenesisRarityDrawing} containerName="GRPie" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return content;
}

const Stats = function Stats() {
  const dispatch = useDispatch();
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetEHStatsQuery();
  let content = null;
  if (isLoading) {
    content = <CustomLoader />;
  } else if (isError) {
    dispatch(setError(error.message || error.status));
  } else if (isSuccess && data.status === 'success') {
    const { data: gdata } = data;
    content = (
      <div className="flex-container">
        <div className="row mt-3">
          <h1>Some statistics</h1>
        </div>
        <div>
          {createboxstate('Time since last update', gdata.time, 'epoch')}
        </div>
        <div className="row">
          <div className="col-xl-12 mb-4">
            <div className="card has-shadow">
              <div className="card-header">Liquidity</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <td>name</td>
                        <td>value</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={nanoid()}>
                        <td>{'Epic hero Holders (balance > 1 EH or have 1 NFT), real Holders'}</td>
                        <td>{gdata.epicHeroStat.totalAddress}</td>
                      </tr>
                      <tr key={nanoid()}>
                        <td>Epic hero Holders with only token (no NFT)</td>
                        <td>{gdata.epicHeroStat.onlyEpic}</td>
                      </tr>
                      <tr key={nanoid()}>
                        <td>Epic hero Holders with only NFT (balance = 0)</td>
                        <td>{gdata.epicHeroStat.onlyHero}</td>
                      </tr>
                      <tr key={nanoid()}>
                        <td>Epic hero Holders with demi still stack in dailyTasks</td>
                        <td>{gdata.epicHeroStat.stackDemi}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DrawChart />
      </div>
    );
  }
  return content;
};

export default Stats;
