import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
    // const storeError = useSelector((state) => state.mainError);
  }

  static getDerivedStateFromError(error) {
    // On met à jour l’état afin que le prochain rendu affiche
    // l’UI de remplacement.
    return { hasError: true, error };
  }

  componentDidCatch(_error, _errorInfo) {
    this.setState({ errorInfo: _errorInfo, error: _error });
    console.error({ _error, _errorInfo });
  }

  render() {
    const { hasError, error, errorInfo } = this.state; // destructing
    const { children } = this.props;

    if (hasError) {
      // Vous pouvez afficher ici n’importe quelle UI de secours
      return (
        <div className="alert alert-danger radius-bordered alert-shadowed" role="alert">
          <strong>{error.toString()}</strong>{JSON.stringify(errorInfo, null, 2)}
        </div>
      );
    }

    return children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
