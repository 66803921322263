import React from 'react';
import PropTypes from 'prop-types';
import AnyChart from './anychart';

class PieCHart extends React.Component {
  constructor(props) {
    super(props);
    const { data, containerName } = this.props;
    this.chart = null;
    this.state = {
      data,
      containerName,
    };
    this.drawChart();
  }

  drawChart() {
    const { anychart } = window;
    const { data } = this.state;

    const chart = anychart.pie(data);
    chart.outline(false);

    const legend = chart.legend();
    legend.enabled(true);
    legend.itemsLayout('vertical-expandable');
    legend.position('right');
    legend.align('center');
    legend.itemsSpacing(5);
    // Set items format.
    legend.itemsFormat(function _() {
      const yvalue = this.getStat('yPercentOfTotal');
      let percent = 0.0;
      if (yvalue) percent = Number.parseFloat(yvalue).toFixed(2);
      return `${this.x} (${percent}%)`;
    });

    const credits = chart.credits();
    credits.enabled(false);
    this.chart = chart;
  }

  render() {
    const { containerName } = this.state;
    return <AnyChart instance={this.chart} id={containerName} width="100%" height={400} />;
  }
}

export default PieCHart;

PieCHart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  containerName: PropTypes.string.isRequired,
};
