import React from 'react';
import { NavLink } from 'react-router-dom';

import WalletButton from '../components/wallet/Walletbutton';
import Balance from '../components/wallet/balance';

const Sidebar = function Sidebar() {
  return (
    <nav id="sidebar">
      <div className="sidebar-header justify-content-center align-items-center">
        <a href="/">
          <img src="./assets/logo-wide.png" height="70px" alt="logo" />
        </a>
        <span className="text-white text-center m-auto">EHS</span>
      </div>
      <hr />
      <ul className="nav nav-pills mb-auto flex-column px-3 py-1">
        <li className="nav-item">
          <NavLink to="/" className="nav-link">
            <i className="fas fa-house" /><span>Home</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/holders" className="nav-link">
            <i className="fas fa-pie-chart" /><span>Holders</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/xlibera" className="nav-link">
            <i className="fas fa-building-columns" /><span>xLibera <span className="badge bg-secondary rounded-pill">coming soon</span></span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/xlibero" className="nav-link">
            <i className="fas fa-building-columns" /><span>xLibero</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/EHstats" className="nav-link">
            <i className="fas fa-chart-line" /><span>EpicHero stat</span>
          </NavLink>
        </li>
        <ul className="nav nav-pills mb-auto flex-column px-md-3">
          <li className="nav-item">
            <NavLink to="/herostats" className="nav-link">
              <i className="fas fa-child" /><span>Stat hero</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/dungeon" className="nav-link">
              <i className="fa-brands fa-fort-awesome" /><span>Dungeon</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/fuse" className="nav-link">
              <i className="fas fa-user-group" /><span>Fusion <span className="badge bg-secondary rounded-pill">coming soon</span></span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/army" className="nav-link">
              <i className="fas fa-dragon" /><span>ARMY</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/market" className="nav-link">
              <i className="fas fa-cart-shopping" /><span>Market</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/roi" className="nav-link">
              <i className="fas fa-hand-holding-usd" /><span> ROI <span className="badge bg-secondary rounded-pill">coming soon</span></span>
            </NavLink>
          </li>
        </ul>
        <li className="nav-item ">
          <NavLink to="/logs" className="nav-link">
            <i className="fas fa-book" /><span>LOGS <span className="badge bg-secondary rounded-pill">coming soon</span></span>
          </NavLink>
        </li>
      </ul>
      <div className="sidebar-footer flex-column">
        <div>
          <Balance />
        </div>
        <div>
          <WalletButton />
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
