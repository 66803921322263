import { configureStore } from '@reduxjs/toolkit';

import errorReducer from './errorSlice';
import priceReducer from './priceSlice';
import web3Reducer from './web3';

import { apiSlice } from './myApiSlice';
import { MoralisApiSlice } from './MoralisApiSlice';

export default configureStore({
  reducer: {
    mainError: errorReducer,
    coinGeckoPrices: priceReducer,
    web3: web3Reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [MoralisApiSlice.reducerPath]: MoralisApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware, MoralisApiSlice.middleware),
});
