import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  price: {
    thoreum: 1.0,
    epichero: 0.1,
    mvc: 0.000001,
    libero: 0.005,
  },
  polling: false,
  interval: 45 * 1000,
};

export const pricesSlice = createSlice({
  name: 'coinGeckoPrices',
  initialState,
  reducers: {
    setPrices: (state, action) => {
      state.thoreum = action.payload.thoreum;
      state.epicHero = action.payload.epicHero;
      state.mvc = action.payload.mvc;
      state.libero = action.payload.libero;
    },
    setPolling: (state) => {
      state.polling = true;
    },
    setInterval: (state, action) => {
      state.interval = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPrices, setPolling, setInterval,
} = pricesSlice.actions;

export default pricesSlice.reducer;
