import React from 'react';
// import { useEthers, useTokenBalance } from '@usedapp/core';
// import { ethers } from 'ethers';
import useWeb3, { useTokenBalance } from './ether';
import {
  EpicHeroCst, ThoreumCst, MCVCst, LiberoCst,
} from '../epicHero/EpicHeroCst';
import { useGetPrices } from '../../api/getCoinGeckoPrice';

const Balance = function Balance() {
  const { account } = useWeb3();

  const tokenBalanceEH = useTokenBalance(EpicHeroCst.Addr, account);
  const tokenBalanceTH = useTokenBalance(ThoreumCst.Addr, account);
  const tokenBalanceMVC = useTokenBalance(MCVCst.Addr, account);
  const tokenBalanceLibero = useTokenBalance(LiberoCst.Addr, account);
  const dataPrices = useGetPrices();

  function formatDollard(bigNumber, price) {
    return (parseFloat(bigNumber) * parseFloat(price)).toFixed(2);
  }
  if (account && dataPrices) {
    return (
      <div className="text-center m-0 pb-3 text-muted balance">
        {tokenBalanceEH > 0 && <div>Balance EH: {parseFloat(tokenBalanceEH).toFixed(2)} ({formatDollard(tokenBalanceEH, dataPrices.epichero)}$)</div>}
        {tokenBalanceTH > 0 && <div>Balance TH: {parseFloat(tokenBalanceTH).toFixed(2)} ({formatDollard(tokenBalanceTH, dataPrices.thoreum)}$)</div>}
        {tokenBalanceMVC > 0 && <div>Balance MVC: {parseFloat(tokenBalanceMVC).toFixed(2)} ({formatDollard(tokenBalanceMVC, dataPrices.mvc)}$)</div>}
        {tokenBalanceLibero > 0 && <div>Balance Libero: {parseFloat(tokenBalanceLibero).toFixed(2)} ({formatDollard(tokenBalanceLibero, dataPrices.libero)}$)</div>}
      </div>
    );
  }
  return null;
};

export default Balance;
