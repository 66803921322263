import React from 'react';

const Home = function Home() {
  return (
    <div className="flex-container ">
      <div className="row">
        <div className="col-xl-12 mb-5">
          <div className="card has-shadow">
            <div className="card-header fs-3">Home</div>
            <div className="card-body">
              <ul>
                <li>Every information is directly computed from Binance Smart Chain</li>
                <li>All data can be changed without notification, Epic Hero dev team can change the behavior of the game at any moment.</li>
                <li>{'Probability is sneaky and if you win or lose a lot of time, it\'s not necessarily a fatality ...'}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 mb-5">
          <div className="card has-shadow">
            <div className="card-header fs-3">Changelog</div>
            <div className="card-body">
              <h4><b>Next features (not orderer)</b></h4>
              <ul>
                <li>acces to information without metamask</li>
                <li>add graph for rewards EH for maximum visibility</li>
                <li>add automatic picker for dungeon to optimize rewards</li>
              </ul>
              <h4><b>V0.1.9:</b></h4>
              <ul>
                <li>add data on libera in holders</li>
              </ul>
              <h4><b>V0.1.8:</b></h4>
              <ul>
                <li>add data on flibero in holders</li>
              </ul>
              <h4><b>V0.1.7:</b></h4>
              <ul>
                <li>add data on xlibero</li>
              </ul>
              <h4><b>V0.1.6:</b></h4>
              <ul>
                <li>make the website responsible for mobile</li>
                <li>dungeon: add yesterday and today stats</li>
                <li>footer: add telegram link to my profile</li>
              </ul>
              <h4><b>V0.1.5:</b> add dungeon</h4>
              <ul>
                <li>dungeon: add rewards in dungeon (EH and Libero)</li>
              </ul>
              <h4><b>V0.1.4:</b> add libero</h4>
              <ul>
                <li>holders: add libero chart</li>
                <li>wallet: add libero balance</li>
              </ul>
              <h4><b>V0.1.3:</b> fix bugs</h4>
              <ul>
                <li>Market: fix bug with tooltip</li>
                <li>army: fix john&apos;s bug with level 13 hero</li>
              </ul>
              <h4><b>V0.1.2:</b> add wallet address</h4>
              <ul>
                <li>Stat hero: add wallet address form to view heroes army</li>
              </ul>
              <h4><b>V0.1.1:</b> add Stat hero</h4>
              <ul>
                <li>Stat hero: add box chart to see the difference of statistics between different hero rariy</li>
                <li>Holders: update to thoreum V2</li>
              </ul>
              <h4><b>V0.1.0:</b> first release</h4>
              <ul>
                <li>Market: you can show all hero sold in market place and buy it directly (use official MP, just better visibility)</li>
                <li>Holders: you can see repartion between all type of holders</li>
                <li>Army: you can see all your army in one click with estimate value</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
