import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetInfoHeroQuery } from '../../store/myApiSlice';
import CustomLoader from '../CustomLoader';
import { setError } from '../../store/errorSlice';
import BoxChart from './boxChart';

function someInfo(epoch) {
  let myDate;
  if (!epoch) myDate = new Date(0);
  else myDate = new Date(epoch);
  return (
    <div className="card has-shadow">
      <div className="card-header fs-3">Some information</div>
      <div className="card-body">
        <ul>
          <li key="aaa">All statistics are compute every 24h</li>
          <li key="bbb">{`the last update was at ${myDate.toLocaleString()}`}</li>
          <li key="ccc">How to read box plot : <a href="https://en.wikipedia.org/wiki/Box_plot" target="_blank" rel="noreferrer">https://en.wikipedia.org/wiki/Box_plot</a></li>
          <li key="ddd">Some value are outbound, this is a bug with the first fusion hero</li>
        </ul>
      </div>
    </div>
  );
}
const HeroStats = function HeroStats() {
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetInfoHeroQuery();
  let content = null;
  let dataStat;
  if (isLoading) {
    content = <CustomLoader />;
  } else if (isError) {
    dispatch(setError(error.message || error.status));
  } else if (isSuccess && data.status === 'success') {
    dataStat = data.data;
    content = (
      <div className="flex-container ">
        <div className="row">
          <div className="col-xl-12 mb-5">
            {someInfo(dataStat.time)}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 mb-5">
            <div className="card has-shadow">
              <div className="card-header pt-4 fs-3">Statistics distribution versus rarity</div>
              <div className="card-body">
                <BoxChart data={dataStat.deviation} containerName="rarityBoxChart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return content;
};

export default HeroStats;
