import { ethers, BigNumber } from 'ethers';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EpicHero, { typeHero } from '../epicHero/EpicHero';
import {
  EpicHeroCst, MarketPlace, ERC20Generic, ThoreumCst,
} from '../epicHero/EpicHeroCst';
import { getProvider } from '../wallet/ether';
// import { Web3Context } from '../../store/context';
import { setError } from '../../store/errorSlice';

const getMarketData = async function getMarketData(type) {
  if (!type) throw new Error('type must be genesis or demi');
  const Data = {
    data: null,
    err: null,
  };

  const defaultProvider = await getProvider();

  const tokenContractGenesis = new ethers.Contract(
    EpicHeroCst.EpicHeroNFTGenesisAddr,
    EpicHeroCst.EpicHeroNFTAbi,
    defaultProvider,
  );
  const tokenContractDemi = new ethers.Contract(
    EpicHeroCst.EpicHeroNFTDemiAddr,
    EpicHeroCst.EpicHeroNFTAbi,
    defaultProvider,
  );
  const contractAddr = (type === typeHero.genesis) ? tokenContractGenesis : tokenContractDemi;
  const accountAddr = (type === typeHero.genesis) ? MarketPlace.AddrGenesis : MarketPlace.AddrDemi;

  const balGenesis = await contractAddr.balanceOf(accountAddr); // Genesis
  console.log(`hero in market: ${balGenesis}`);
  // récupère la liste
  const promise = [];
  for (let index = 0; index < balGenesis; index += 1) {
    promise.push(contractAddr.tokenOfOwnerByIndex(accountAddr, index));
  }
  const start = Date.now();
  const response = await Promise.allSettled(promise);
  const end = Date.now();
  console.debug(`t: ${end - start}ms`);
  // converti en tableau
  const heroId = [];
  response.forEach((item) => {
    if (item.status === 'fulfilled') {
      heroId.push(item.value.toNumber());
    }
  });
  // réarrange
  heroId.sort((a, b) => a - b);
  // console.log(heroId);
  const heroesId = heroId.map((hero) => ({ ID: hero, Daily: 'Market' }));

  const res = await EpicHero.getHeroInfo(heroesId, defaultProvider, type);
  Data.data = res;
  return Data;
};
// export default getMarketData;

export const getAllTrades = async function useGetAllTrades({ type }) {
  const Data = {
    data: null,
    err: null,
  };
  const defaultProvider = await getProvider();
  let addr = null;
  if (type === typeHero.genesis) addr = MarketPlace.AddrGenesis;
  else addr = MarketPlace.AddrDemi;

  try {
    const tokenContract = new ethers.Contract(
      addr,
      MarketPlace.Abi,
      defaultProvider,
    );
    const res = await tokenContract.getOpenTrades();
    console.debug(res);
    const trades = [];
    res.forEach((trade) => {
      // clean info to be return
      trades.push({ tokenId: trade.tokenId, tokenPrice: ethers.utils.formatEther(trade.tokenPrice) });
    });
    console.debug(trades);
    Data.data = trades;
  } catch (error) {
    Data.err = error;
    return Data;
  }

  return Data;
};

export const getTrades = async function useGetTrades({ heroes, price }) {
  const tmpHeroes = heroes;
  console.debug(`search market price for ${tmpHeroes.length} heroes`);

  const defaultProvider = await getProvider();

  const tokenContractGenesis = new ethers.Contract(
    MarketPlace.AddrGenesis,
    MarketPlace.Abi,
    defaultProvider,
  );
  const tokenContractDemi = new ethers.Contract(
    MarketPlace.AddrDemi,
    MarketPlace.Abi,
    defaultProvider,
  );
  const promise = [];
  tmpHeroes.forEach((element) => {
    const contractAddr = (element.type === typeHero.genesis) ? tokenContractGenesis : tokenContractDemi;
    promise.push(contractAddr.getOpenTradeByToken(element.ID));
  });
  const response = await Promise.allSettled(promise);
  for (let index = 0; index < response.length; index += 1) {
    const element = response[index];
    if (element.status === 'fulfilled' && price) {
      const value = ethers.utils.formatEther(element.value.tokenPrice);
      const valDoll = (parseFloat(value) * price).toFixed(2);
      tmpHeroes[index].tradeId = element.value.id;
      tmpHeroes[index].value = value;
      tmpHeroes[index].valueDoll = valDoll;
    }
  }

  return tmpHeroes;
};

export const getMarketActive = async function getMarketActive() {
  // const dispatch = useDispatch();
  let dataGenesis = true;
  let dataDemi = true;
  let err = null;
  const provider = await getProvider();
  try {
    const tokenContractGenesis = new ethers.Contract(
      MarketPlace.AddrGenesis,
      MarketPlace.Abi,
      provider,
    );
    const tokenContractDemi = new ethers.Contract(
      MarketPlace.AddrDemi,
      MarketPlace.Abi,
      provider,
    );

    dataGenesis = await tokenContractGenesis.isClosed();
    dataDemi = await tokenContractDemi.isClosed();
  } catch (error) {
    // dispatch(setError(error));
    err = error;
  }
  return { genesis: !dataGenesis, demi: !dataDemi, err };
};

export const getAuthorize = async function getAuthorize() {
  let owner = null;
  let bgen = false;
  let bdemi = false;

  const provider = await getProvider();
  try {
    const signer = provider.getSigner();
    owner = await signer.getAddress();
  } catch (e) {
    return { address: null, genesis: false, demi: false };
  }

  const contract = new ethers.Contract(
    ThoreumCst.Addr,
    ERC20Generic.Abi,
    provider,
  );

  const authorizeDemi = await contract.allowance(owner, MarketPlace.AddrDemi);
  bdemi = authorizeDemi.gt(BigNumber.from(0));
  const authorizeGenesis = await contract.allowance(owner, MarketPlace.AddrGenesis);
  bgen = authorizeGenesis.gt(BigNumber.from(0));

  return { address: owner, genesis: bgen, demi: bdemi };
};

export const approvalTrade = async function useApprovalTrade(type) {
  // const { provider } = useWeb3();
  const provider = await getProvider();
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    ThoreumCst.Addr,
    ERC20Generic.Abi,
    signer,
  );

  await contract.approve((type === typeHero.genesis) ? MarketPlace.AddrGenesis : MarketPlace.AddrDemi, ethers.constants.MaxUint256);
};

export const useGetMarketData = function useGetMarketData(type) {
  const tokenPrices = useSelector((state) => state.coinGeckoPrices);
  const { active } = useSelector((state) => state.web3);
  const [isLoading, setLoading] = useState(true);
  const [result, setResult] = useState({ heroes: [] });
  const Storage = window.sessionStorage;
  // let isLoading = true;

  useEffect(() => {
    async function fetchMarketData() {
      setLoading(true);
      const dataEH = { heroes: [], authorizeToBuy: { address: null, genesis: false, demi: false } };
      const timeData = Storage.getItem(`market${type}time`);
      if (!timeData || (Date.now() - timeData) > 15 * 60 * 1000) { // request if time < 15 min
        const { data, err } = await getMarketData(type);
        if (err) throw new Error(err);
        dataEH.heroes = data;
        console.debug('fetch blockchain: OK');
        dataEH.heroes = await EpicHero.getInfoCharacters(dataEH.heroes);
        console.debug('get post data: OK');
        if (tokenPrices.thoreum) {
          dataEH.heroes = await getTrades({ heroes: dataEH.heroes, price: tokenPrices.thoreum });
          console.debug('get trades: OK');
        }
        Storage.setItem(`market${type}`, JSON.stringify(dataEH.heroes));
        Storage.setItem(`market${type}time`, Date.now());
      } else {
        dataEH.heroes = await JSON.parse(Storage.getItem(`market${type}`));
        console.debug('load from storage');
      }
      if (active) {
        dataEH.authorizeToBuy = await getAuthorize();
      }
      setResult(dataEH);
      setLoading(false);
    }
    if (type !== null) fetchMarketData();
  }, [type]);

  return { result, isLoading };
};
