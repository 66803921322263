import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { cleanMsg } from '../store/errorSlice';

const ErrorHandler = function ErrorHandler(props) {
  const { children } = props;
  const { message, isError } = useSelector((state) => state.mainError);
  const dispatch = useDispatch();

  const buttonError = () => <button type="button" className="btn btn-danger btn-sm position-absolute top-50 end-0 translate-middle" aria-label="refresh" onClick={() => window.location.reload()}>Refresh</button>;
  const buttonWarning = (id) => <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => dispatch(cleanMsg(id))} />;

  const messageError = message.map((txt) => (
    <div
      key={txt.id}// generate unique key
      className={`alert ${(txt.type === 'error') ? 'alert-danger' : 'alert-warning'} radius-bordered alert-shadowed alert-dismissible fade show`}
      role="alert"
    >
      <i className="fas fa-triangle-exclamation" /> {(txt.type === 'error') ? 'ERROR' : 'WARNING'}: {`${txt.message}`}
      {(txt.type === 'error') ? buttonError() : buttonWarning(txt.id)}
    </div>
  ));

  if (!isError) return <div>{messageError}{children}</div>;
  return (
    <div>
      {messageError}
    </div>
  );
};

export default ErrorHandler;

ErrorHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
