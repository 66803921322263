import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Web3ArmyRender from './Web3armyRender';
import useWeb3 from '../wallet/ether';
import CustomLoader from '../CustomLoader';
import Web3army from './Web3army';
import EpicHero from '../epicHero/EpicHero';

function SearchAddress({ address = '' }) {
  const { account, active } = useWeb3();
  const [validated, setValidated] = useState(false);
  const [walletAddress, setWallet] = useState(address);
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    console.log('click');
    navigate(`./${walletAddress}`, { replace: true });
  }
  function handleChange(e) {
    setWallet(e.target.value);
    let validate;
    try {
      validate = ethers.utils.getAddress((e.target.value).toLowerCase());
    } catch (error) {
      validate = null;
      setValidated(false);
    }
    if (validate) setValidated(true);
  }

  useEffect(() => {
    if (active && account) {
      setWallet(account);
      setValidated(true);
    }
  }, [account, active]);

  useEffect(() => {
    if (walletAddress) {
      setValidated(ethers.utils.isAddress(walletAddress));
    }
  }, [walletAddress]);

  return (
    <div>
      <div className="row">
        <div className="card has-shadow px-0 rounded">
          <div className="card-body table-responsive pt-2 pb-2">
            <form className="needs-validation" noValidate>
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label">Wallet Address:</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className={(validated) ? 'form-control is-valid' : 'form-control is-invalid'}
                    placeholder="wallet Adress"
                    value={walletAddress}
                    aria-label="wallet adress"
                    aria-describedby="basic-addon2"
                    required
                    style={{ borderRadius: '500px 0 0 500px' }}
                    onChange={handleChange}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      disabled={!(validated)}
                      type="button"
                      style={{ borderRadius: '0 500px 500px 0', width: '80px' }}
                      onClick={handleSubmit}
                    ><i className="fas fa-magnifying-glass" />
                    </button>
                  </div>
                  <div className="invalid-feedback">
                    Please provide a valid address.
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchAddress.propTypes = {
  address: PropTypes.string.isRequired,
};

function ShowArmyTable({ ValidAddress }) {
  // console.log(`ValidAddress: ${ValidAddress}`);
  const [isLoading, setLoading] = useState(true);
  const [heroes, setHeroes] = useState(null);
  const { price } = useSelector((state) => state.coinGeckoPrices);
  const componentMounted = useRef(true);

  useEffect(() => {
    async function loadData() {
      console.log('update heroes');
      let { data } = await Web3army(ValidAddress);
      if (price) {
        data = await EpicHero.getInfoCharacters(data);
        data.map((hero) => hero.computeValue({ epicheroPrice: price.epichero, thoreumPrice: price.thoreum }));
      }
      return data;
    }
    loadData()
      .then((data) => {
        if (componentMounted.current) {
          setHeroes(data);
          setLoading(false);
        }
      });
    return function cleanup() {
      componentMounted.current = false;
    };
  }, [ValidAddress, price]);

  if (isLoading) {
    return (
      <div>
        <div className="row pt-3">
          <div className="card has-shadow rounded">
            <div className="card-body table-responsive">
              <CustomLoader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row pt-3">
      <div className="card has-shadow rounded">
        <div className="card-header">
          <h3>Your army</h3>
        </div>
        <div className="card-body table-responsive p-0">
          {heroes && <Web3ArmyRender troops={heroes} />}
        </div>
      </div>
    </div>
  );
}
ShowArmyTable.propTypes = {
  ValidAddress: PropTypes.string.isRequired,
};
function ShowArmy() {
  const params = useParams();
  let address = '';

  let validAddr;
  if (params.address) {
    validAddr = ethers.utils.isAddress(params.address);
    if (validAddr) {
      // console.log('valid address');
      address = params.address;
    }
  }

  return (
    <div>
      <SearchAddress address={address} />
      {validAddr && <ShowArmyTable ValidAddress={address} />}
    </div>
  );
}

export default ShowArmy;
