/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import {
  useTable, usePagination, useFilters, useSortBy,
} from 'react-table';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm';
import EpicHero, { typeHero } from '../epicHero/EpicHero';
import executeTrade from './executeTrade';
import { approvalTrade } from './getMarket';

function SelectColumnFilter({
  column: {
    filterValue, setFilter, preFilteredRows, id,
  },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function RarityColumnFilter({
  column: {
    filterValue, setFilter,
  },
}) {
  const options = ['chest', 'Common', 'Uncommon', 'Rare', 'Super Rare', 'Legendary', 'Mythical', 'Epic'];

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: {
    filterValue = [], preFilteredRows, setFilter, id,
  },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div className="input-group input-group-sm m-0">
      <input
        type="text"
        className="form-control text-white"
        placeholder={`Min (${min})`}
        aria-label="min"
        value={filterValue[0] || ''}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        style={{ width: '10%' }}
      />
      <span className="input-group-text">to</span>
      <input
        type="text"
        className="form-control text-white"
        placeholder={`Max (${max})`}
        aria-label="max"
        value={filterValue[1] || ''}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        style={{ width: '10%' }}
      />
    </div>
  );
}

function SetbuyButton({ hero, authorized }) {
  const tooltipRef = useRef();
  let buyButton = null;
  let msg = 'Your wallet need to be connected';
  if (!authorized.address) buyButton = <div className="badge rounded-pill bg-grey text-black has-shadow" ref={tooltipRef}><i className="fas fa-cart-shopping disabled" aria-label="buy" style={{ color: 'black' }} /></div>;
  else if ((hero.type === typeHero.genesis && authorized.genesis) || (hero.type === typeHero.demi && authorized.demi)) {
    msg = 'buy it';
    buyButton = <a type="button" className="badge rounded-pill bg-primary text-black has-shadow" onClick={() => executeTrade(hero.tradeId, hero.type)} ref={tooltipRef}><i className="fas fa-cart-shopping disabled" aria-label="buy" style={{ color: 'black' }} /></a>;
  } else {
    msg = 'need to be unlock';
    buyButton = <a type="button" className="badge rounded-pill bg-primary text-black has-shadow" onClick={() => approvalTrade(hero.type)} ref={tooltipRef}>unlock</a>;
  }
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const tooltip = new Tooltip(tooltipRef.current, {
      title: `${msg}`,
      placement: 'right',
      trigger: 'hover',
      animation: true,
    });
  });

  return buyButton;
}

function TooltipDeal({ hero, marketPrice }) {
  const tooltipRef = useRef();
  const THero = new EpicHero(hero.type, hero.ID, hero.level, hero.rarity);
  THero.fillValues({
    level: hero.level,
    rarity: hero.rarity,
    name: hero.name,
    stat: hero.stat,
    className: hero.className,
  });
  const { good: goodDeal, msg } = THero.goodDeal(marketPrice);
  // const msg = '';
  // const goodDeal = 'good';

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const tooltip = new Tooltip(tooltipRef.current, {
      title: `${msg}`,
      placement: 'right',
      trigger: 'hover',
      animation: true,
    });
  });

  return (
    <div className="p-0">
      <i className={`fas ${(goodDeal === 'good') ? 'fa-thumbs-up' : (goodDeal === 'bad') ? 'fa-thumbs-down' : 'fa-ellipsis'}`} ref={tooltipRef} />
    </div>
  );
}

const MarketTable = function MarketTable({ data, authorized }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Id',
      accessor: 'ID',
      disableFilters: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      filter: 'equals',
    },
    {
      Header: 'Type',
      accessor: 'className',
    },
    {
      Header: 'Rarity',
      accessor: 'rarity',
      Filter: RarityColumnFilter,
      filter: 'equals',
    },
    {
      Header: 'Level',
      accessor: 'level',
    },
    {
      Header: 'Stat',
      accessor: 'stat',
      Filter: NumberRangeColumnFilter,
      filter: 'between',
    },
    {
      Header: 'Sale price',
      accessor: 'salePrice',
      Filter: NumberRangeColumnFilter,
      filter: 'between',
    },
    {
      Header: 'Sale price ($)',
      accessor: 'salePriceDoll',
      Filter: NumberRangeColumnFilter,
      filter: 'between',
    },
    {
      Header: 'Good deal?',
      accessor: 'goodDeal',
      disableFilters: true,
    },
    {
      Header: 'Buy it',
      accessor: 'buy',
      disableFilters: true,
    },
  ], []);

  const parserdata = React.useMemo(() => {
    console.log(`memo: parse data of ${data.length} heroes`);
    const tab = [];
    data.forEach((hero) => {
      let sumStat = 0;
      if (hero.getInfo) sumStat = Object.values(hero.stat).reduce((total, amount) => total + amount);
      const link = `https://epichero.io/${(hero.type === typeHero.genesis) ? 'hero' : 'demi'}/${hero.ID}`;

      tab.push({
        ID: <a className="badge rounded-pill bg-primary text-dark has-shadow" href={link}>{hero.ID}</a>,
        tradeId: hero.tradeId,
        name: hero.name,
        className: hero.className,
        rarity: hero.rarity,
        level: hero.level,
        stat: sumStat,
        salePrice: hero.value,
        salePriceDoll: hero.valueDoll,
        goodDeal: <TooltipDeal hero={hero} marketPrice={hero.value} />,
        buy: <SetbuyButton hero={hero} authorized={authorized} />,
      });
    });
    return tab;
  }, [authorized, data]);

  return (
    <div>
      <Table columns={columns} data={parserdata} />
    </div>
  );
};
export default MarketTable;

function Table({ columns, data }) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: SelectColumnFilter,
    }),
    [],
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
      defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  // Render the UI for your table
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-sm text-center align-middle" {...getTableProps()}>
        <thead className="bg-secondary text-white">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="justify-content-center" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{' '}
                    {column.isSorted ? column.isSortedDesc ? <i className="fas fa-sort-down" /> : <i className="fas fa-sort-up" /> : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="justify-content-center align-middle" {...column.getHeaderProps()}>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="text-center pb-2">
        {/* custom pagination */}
        <nav aria-label="Pagination">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${(!canPreviousPage) ? 'disabled' : ''}`}>
              <a className="page-link" onClick={() => gotoPage(0)} role="button">First</a>
            </li>
            <li className={`page-item ${(!canPreviousPage) ? 'disabled' : ''}`}>
              <a className="page-link" onClick={() => previousPage()} role="button">Previous</a>
            </li>

            <li className={`page-item ${(!canNextPage) ? 'disabled' : ''}`}>
              <a className="page-link" onClick={() => nextPage()} role="button">Next</a>
            </li>
            <li className={`page-item ${(!canNextPage) ? 'disabled' : ''}`}>
              <a className="page-link" onClick={() => gotoPage(pageCount - 1)} role="button">Last</a>
            </li>
          </ul>
        </nav>
        <div className="text-center">
          <span>Page{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong></span>
          {' '}
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '50px', height: '25px' }}
            />
          </span>
          {'  '}
          <select
            className="custom-select custom-select-sm"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[15, 30, 60, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize} heroes
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
