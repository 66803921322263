import axios from 'axios';

const urlApi = 'https://my-api.grutatest.workers.dev/';
const headers = {
  'Content-Type': 'text/plain',
};

async function postDataAxios(dataHeroes) {
  try {
    const resp = await axios.post(`${urlApi}/epicHero/getHeroes`, dataHeroes, { headers });
    if (resp.status) return resp.data;
  } catch (error) { console.error(error); }
  return null;
}

export default postDataAxios;
